export const QUOTES = [
  'Сьогодні день задатків, ловимо угоди!',
  'Ти космос! Ринок нерухомості тримається на тобі!',
  'Ти рієлтор номер 1 у цьому місті – так тримати!',
  "Кожен твій об'єкт – це знахідка для ідеального клієнта!",
  'Ти магніт для угод, продовжуй у тому ж дусі!',
  'Десь прямо зараз твоя угода йде до тебе, будь готовий!',
  'Відкати назад? Не сьогодні! Вперед до рекордів!',
  'Поки інші вагаються, ти вже заробляєш!',
  'Бомба-рієлтор! Угода сьогодні буде точно!',
  'Ти не шукаєш клієнтів – вони шукають тебе!',
  'Продаж за три дні? Легко! Для тебе це стандарт!',
  'Ти твориш дива!',
  'Якщо не ти, то хто? Сьогодні день твого успіху!',
  'На ринку ти не просто гравець, ти – трендсеттер!',
  'Продаєш так, що навіть власники в захваті!',
  'Кожен твій показ – це шоу, де клієнти хочуть купити!',
  'Ти – локомотив продажів, вперед без зупинок!',
  "Рекорд тижня? А давай його поб'ємо!",
  'Твій KPI – це захоплення клієнтів!',
  'Покажемо ринку, як працюють справжні професіонали!',
  'Угода за угодою, крок за кроком – до вершини!',
  'Ти продаєш так, що клієнти рекомендують тебе друзям!',
  'Ти – ходячий каталізатор ринку нерухомості!',
  'Ще один крок, ще один показ, ще одна угода!',
  'Сьогодні – день ідеального клієнта, зустрічай його!',
  'Ти – вогонь! Успіх не може пройти повз тебе!',
  'Ти не просто рієлтор, ти творець нових історій для людей.',
  'Твоя робота змінює життя – продовжуй так само впевнено!',
  'Кожна вдало закрита угода – ще один крок до твоєї мрії.',
  'Енергія, знання та наполегливість – ідеальне поєднання для успіху!',
  'Твоє вміння знайти підхід до кожного клієнта – безцінне.',
  'Довіра клієнтів – твоя найбільша нагорода.',
  'Ти вмієш бачити можливості там, де інші бачать проблеми.',
  'Твоя експертність робить ринок прозорішим.',
  'Рієлтор – це не просто професія, це стиль життя.',
  'Кожна нова угода – це ще один рівень твоєї майстерності.',
  'Справжній професіонал – це той, хто не здається перед труднощами.',
  'Ти знаєш ринок як свої п’ять пальців – і це твоя суперсила!',
  'Будь тим рієлтором, якого сам би обрав для себе.',
  'Ніяких випадковостей – тільки досвід, знання та стратегія!',
  'Велика угода починається з маленької довіри.',
  'Ти не просто закриваєш угоди – ти відкриваєш двері в нове життя!',
  'Впевнений крок до цілі – ось що відрізняє справжніх професіоналів.',
  'Сьогодні клієнт – завтра друг, який рекомендуватиме тебе іншим.',
  'Твоя репутація – твій найкращий актив.',
  'Де інші бачать перешкоди, ти бачиш можливості.',
  'Наполегливість і чесність – твій ключ до довгострокового успіху.',
  'Якщо клієнт сумнівається – ти той, хто допоможе прийняти правильне рішення.',
  'Великий рієлтор починається з великого бажання допомагати людям.',
  'Впевненість + професіоналізм = довіра та гарні угоди.',
  'Ти не працюєш, ти створюєш майбутнє для своїх клієнтів!',
  'Кожен день – це нова можливість зробити ще одну вдалу угоду.',
  'Досвід – це дорогоцінність, яку ти примножуєш з кожним днем.',
  'З таким рієлтором, як ти, клієнти можуть бути спокійні – їх мрії у надійних руках!',
  'Сьогодні ідеальний день, щоб закрити круту угоду!',
  'Ти рієлтор, який змінює ринок – нехай це знають усі!',
  'Успіх – це питання часу. І цей час настав!',
  'Будь тим, кого рекомендують без сумнівів!',
  'Новий день – нові можливості, хапай їх!',
  'Ти не просто працюєш, ти створюєш історії!',
  'Кожен показ – це шанс зробити когось щасливішим!',
  'Вперед до рекордів! Сьогодні день великих угод!',
  'Посміхнись – світло всередині тебе приваблює удачу!',
  'Конкуренція? Ні, ти просто задаєш планку!',
  'Ти – майстер переговорів, будь-який клієнт скаже «так»!',
  'Вставай, світ нерухомості чекає на тебе!',
  'Час показувати всім, як виглядає справжній професіонал!',
  'Ти кращий у своїй справі – і це лише початок!',
  'Впевненість + знання = непереможний ти!',
  'Кожен новий день – ще один крок до мрії!',
  'Сьогодні всі двері відкриваються для тебе!',
  'Ти створюєш комфорт і затишок у житті інших!',
  'Візьми максимум від цього дня – він для тебе!',
  'Втома – не виправдання, ти народжений для перемог!',
  'Клієнти вже шукають саме тебе, дій!',
  'Немає меж для того, хто не зупиняється!',
  'Твої навички – твоя суперсила, використовуй її!',
  'Ринок чекає на твій прорив, зроби це сьогодні!',
  'Справжній профі не чекає шансів, він їх створює!',
  'Великий успіх починається з маленьких перемог!',
  'Гроші люблять тих, хто не боїться діяти!',
  'Ти – бренд, про який говоритимуть всі!',
  'Піднімай голову, випромінюй впевненість і завойовуй цей день! 🚀',
  'Сьогодні новий день – твій шанс зробити його особливим!',
  'Ти сильніший, ніж думаєш, і здатний на більше, ніж уявляєш!',
  'Не бійся робити крок вперед – все найкраще починається поза зоною комфорту.',
  'Навіть маленький крок сьогодні наближає тебе до великої мети.',
  'Ти вже маєш усе, що потрібно, щоб перемагати!',
  'Не порівнюй себе з іншими – ти унікальний!',
  'Віра в себе – це перший крок до великих звершень!',
  'Все, що ти шукаєш, вже шукає тебе!',
  'Твоє майбутнє починається прямо зараз – зроби його кращим!',
  'Живи сьогодні так, щоб завтра собі подякувати!',
  'Усміхнись – світ стане трішки яскравішим!',
  'Відпусти сумніви і просто зроби перший крок!',
  'Ти важливий, цінний і неповторний – завжди пам’ятай це!',
  'Сьогодні твій день, нехай він буде наповнений світлом!',
  'Не поспішай – навіть у тиші народжуються великі ідеї.',
  'Головне – не боятися помилок, адже вони ведуть до досвіду.',
  'Дихай глибше, думай ширше, живи яскравіше!',
  'Що б не сталося вчора, сьогодні – новий початок!',
  'Життя любить сміливих – не зупиняйся!',
  'Будь добрим до себе – ти заслуговуєш любов і повагу.',
  'Лише ти вирішуєш, яким буде твій день!',
  'Навіть найдовша подорож починається з першого кроку.',
  'Довіряй собі – ти знаєш, що робити!',
  'У тебе є сила змінювати світ, почни з себе!',
  'Ти вже набагато ближче до своєї мрії, ніж вчора.',
  'Відпочинь, але не здавайся – ти йдеш у правильному напрямку!',
  'Радій простим речам, бо вони і є найбільшим щастям.',
  'Все, що потрібно для щастя, вже є всередині тебе.',
  'Щодня у тебе є можливість зробити щось прекрасне!',
  'Відпусти страх і живи на повну! 🚀✨',
  //
  'Не зупиняйся, навіть якщо тобі важко. Тому що важко буде завжди.<br>– Ілон Маск',
  'Єдиний спосіб зробити видатну роботу – щиро любити те, що робиш.<br>– Стів Джобс',
  'Той, хто хоче бачити результати негайно, має йти працювати в швейну фабрику.<br>– Альберт Ейнштейн',
  'Ваш час обмежений, тому не витрачайте його, живучи чужим життям.<br>– Стів Джобс',
  'Не чекайте. Час ніколи не буде ідеально правильним.<br>– Наполеон Хілл',
  'Перемога – це не випадковість. Це праця, наполегливість, навчання, жертви і, перш за все, любов до того, що ти робиш.<br>– Пеле',
  'Не дозволяй учорашньому дню забрати надто багато сьогоднішнього.<br>– Вілл Роджерс',
  'Успіх – це вміння йти від невдачі до невдачі без втрати ентузіазму.<br>– Вінстон Черчилль',
  'Людина стає тим, про що вона думає більшу частину часу.<br>– Ерл Найтінгейл',
  'Не варто боятися повільного руху вперед, варто боятися стояння на місці.<br>– Китайське прислів’я',
  'Жоден вітер не буде попутним для того, хто не знає, куди плисти.<br>– Сенека',
  'Щоб досягти успіху, ваше бажання має бути більшим за ваш страх перед невдачею.<br>– Білл Косбі',
  'Свобода нічого не варта, якщо вона не включає в себе свободу помилятися.<br>– Махатма Ганді',
  'Успіх – це 1% таланту і 99% праці.<br>– Томас Едісон',
  'Щоб зробити щось велике, потрібно почати з малого.<br>– Лао-цзи',
  'Справжнє задоволення приходить від подолання складних речей.<br>– Теодор Рузвельт',
  'Не дозволяйте шуму чужих думок заглушити ваш власний внутрішній голос.<br>– Стів Джобс',
  'Якщо ти народився без крил, не заважай їм рости.<br>– Коко Шанель',
  'Лише ті, хто наважиться на велику поразку, можуть досягти великого успіху.<br>– Роберт Кеннеді',
  'Щастя – це не щось готове. Воно приходить від ваших власних дій.<br>– Далай-Лама',
  'Секрет успіху – це розпочати.<br>– Марк Твен',
  'Той, хто не робить помилок, зазвичай нічого не робить.<br>– Теодор Рузвельт',
  'Немає нічого неможливого, якщо ти віриш у себе.<br>– Брюс Лі',
  'Ви ніколи не перетнете океан, якщо не наважитеся втратити берег з поля зору.<br>– Христофор Колумб',
  'Щоб дійти до мети, треба перш за все йти.<br>– Оноре де Бальзак',
  'Не бійся досконалості – тобі її ніколи не досягти.<br>– Сальвадор Далі',
  'Краще зробити і шкодувати, ніж шкодувати, що не зробив.<br>– Люсьєн де Вовенарг',
  'Майбутнє належить тим, хто вірить у красу своїх мрій.<br>– Елеонора Рузвельт',
  'Ми стаємо тим, що ми постійно робимо. Досконалість – це не дія, а звичка.<br>– Арістотель',
  'Єдиний спосіб побороти страх – це діяти.<br>– Дейл Карнегі',
  'Все можливо, якщо ти достатньо сильно цього хочеш.<br>– Лес Браун',
  'Ваша найбільша битва – це битва з самим собою.<br>– Робін Шарма',
  'Не дозволяйте маленькому розуму казати вам, що ваші мрії занадто великі.<br>– Джон Максвелл',
  'Ваші звички формують вашу долю.<br>– Брайан Трейсі',
  'Ми створені для великих речей – пам’ятайте про це щодня.<br>– Тоні Роббінс',
  'Ваше життя зміниться тільки тоді, коли ви зміните щось у своєму повсякденному житті.<br>– Джим Рон',
  'Якщо ти народився бідним – це не твоя вина, але якщо помреш бідним – це твоя відповідальність.<br>– Білл Гейтс',
  'Ваша енергія привертає те, що ви випромінюєте.<br>– Джо Діспенза',
  'Люди переоцінюють, що вони можуть зробити за рік, і недооцінюють, що можуть зробити за десять.<br>– Тоні Роббінс',
  'Не важливо, що сталося вчора. Важливо, що ти зробиш сьогодні.<br>– Робін Шарма',
  'Страх – це ілюзія. Він зникне, коли ти зробиш перший крок.<br>– Наполеон Хілл',
  'Фокусуйтесь не на проблемах, а на можливостях.<br>– Джон Кехо',
  'Ви можете зробити все, якщо ви достатньо сміливі, щоб повірити в це.<br>– Опра Вінфрі',
  'Кожна велика історія починається з рішення спробувати.<br>– Джек Кенфілд',
  'Єдина межа між тобою та твоєю мрією – це ти сам.<br>– Лес Браун',
  'Коли ти приймаєш рішення діяти – світ починає тобі допомагати.<br>– Боб Проктор',
  'Дисципліна – це міст між мріями та реальністю.<br>– Джим Рон',
  'Не бійся почати спочатку. На цей раз ти почнеш не з нуля, а з досвідом.<br>– Джон Максвелл',
  'Якщо ти чогось дуже хочеш – ти обов’язково знайдеш спосіб.<br>– Брайан Трейсі',
  'Секрет успіху – щоденне вдосконалення, нехай навіть на 1%.<br>– Тоні Роббінс',
  'Відкинь виправдання – ти або знаходиш спосіб, або знаходиш причину.<br>– Грант Кардон',
  'Коли ти змінюєш свої думки – ти змінюєш своє життя.<br>– Вейн Дайер',
  'Багатство починається у свідомості.<br>– Роберт Кійосакі',
  'Найкращий момент, щоб посадити дерево, був 20 років тому. Наступний найкращий – сьогодні.<br>– Джим Рон',
  'Перестань чекати і почни створювати своє майбутнє.<br>– Брайан Трейсі',
  'Ваше оточення або допомагає вам рости, або тягне вас вниз. Вибирайте мудро.<br>– Тім Ферріс',
  'Є тільки дві можливості: або ти керуєш своїм життям, або ним керує хтось інший.<br>– Тоні Роббінс',
  'Не думай, що ти не готовий. Просто почни!<br>– Джек Ма',
  'Якщо хочеш жити по-іншому – почни думати по-іншому.<br>– Джо Вітале',
  'Не чекай на ідеальний момент – просто зроби цей момент ідеальним.<br>– Робін Шарма'
];
