<template>
  <div
    class="phone-input"
    :class="{
      _disabled: disabled,
      _lg: isLg,
      _landing: isLanding
    }"
  >
    <div class="phone-input__row">
      <input
        type="text"
        :value="isLanding ? '+380' : '+38'"
        readonly
        :size="isLanding ? 3 : 2"
        aria-hidden="true"
        class="phone-input__prefix"
      />

      <the-mask
        v-model="localValue"
        :mask="isLanding ? '## ## ## ###' : '### ## ## ###'"
        :key="maskKey"
        type="tel"
        :masked="false"
        :placeholder="isLanding ? '__ __ __ ___ ' : '___ __ __ ___'"
        class="phone-input__input"
        aria-label="Введіть номер телефону"
        :disabled="disabled"
        :readonly="readOnly"
        @paste.native="onPaste"
      ></the-mask>
    </div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  name: 'PhoneInput',
  components: {
    TheMask
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      maskKey: 0
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    isLg() {
      return this.size && this.size === 'lg';
    },
    isLanding() {
      return this.mode && this.mode === 'landing';
    }
  },
  methods: {
    onPaste(e) {
      e.preventDefault();

      const pastedValue = e.clipboardData.getData('text');
      let cleanValue = pastedValue.replace(/\D+/g, '').trim();

      if (
        this.isLanding &&
        cleanValue.length === 12 &&
        cleanValue.startsWith('380')
      ) {
        cleanValue = cleanValue.slice(3);
      } else if (
        !this.isLanding &&
        cleanValue.length === 12 &&
        cleanValue.startsWith('380')
      ) {
        cleanValue = cleanValue.slice(2);
      } else if (cleanValue.length === 9 && !cleanValue.startsWith('0')) {
        cleanValue = '0' + cleanValue;
      }

      this.maskKey++;
      this.localValue = cleanValue;
    }
  }
};
</script>

<style lang="sass">
.phone-input
  --input-height: 1.5rem

  position: relative
  border: none
  border-radius: 1rem
  background-color: var(--color-blue-whale)
  font-size: 0.8125rem
  font-weight: 200
  line-height: 1
  color: var(--color-white)

  &._disabled
    opacity: 0.8

  &._lg
    --input-height: 2.5rem

    border: var(--input-border-width) solid var(--input-border-color)
    border-radius: var(--input-border-radius)
    width: 100%
    background-color: var(--input-bg-color)
    font-size: var(--input-font-size)
    font-weight: var(--input-font-weight)
    line-height: var(--input-line-height)
    color: var(--input-text-color)
    transition: border-color var(--transition-duration-primary)

  &._landing
    --input-height: 2.25rem
    --input-font-size: 1rem
    --input-font-weight: 300
    --input-line-height: 1.2

    border: 1px solid var(--color-easy-mint)
    border-radius: var(--input-height)
    background-color: var(--color-easy-white)
    font-size: var(--input-font-size)
    font-weight: var(--input-font-weight)
    line-height: var(--input-line-height)
    color: var(--color-easy-blue)
    overflow: hidden

    .phone-input__row
      padding: 0.125rem 0.5rem 0.125rem 2.375rem

      @include media-breakpoint-down(md)
        padding-left: 1.75rem

    .phone-input__input
      background-color: inherit

  &__row
    display: flex
    min-height: var(--input-height)
    padding: 0.125rem 0.5rem 0.125rem 1rem

  &__prefix
    min-width: 1.75rem
    flex-shrink: 0

  &__input
    flex: 1 1 auto

  input
    border: none
    align-self: stretch
    background: none
    font-size: inherit
    font-weight: inherit
    line-height: inherit
    color: inherit

    &:focus
      outline: none
</style>
