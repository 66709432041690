<template>
  <article class="catalog-card">
    <b-tooltip
      v-if="apartment.is_archive === '1' && isDesktop"
      :target="`tooltip-target-${apartment.id}`"
      triggers="hover"
    >
      Об'єкт продано, через 3 дні після підтвердження буде перенесено до архіву!
    </b-tooltip>

    <div
      :id="`tooltip-target-${apartment.id}`"
      class="d-flex flex-column flex-lg-row card-block"
      :class="apartment.is_archive === '1' ? 'card-block--arhived' : ''"
    >
      <div>
        <!-- Desktop -->
        <div
          v-if="isDesktop"
          class="catalog-item item d-block"
          @click="showModal(apartment.id, $event)"
        >
          <div class="catalog-card__img-wrap">
            <div
              class="catalog-card__img"
              :style="{
                backgroundImage: `url(${mainImg})`
              }"
            >
              <ul class="catalog-card__info-icons">
                <li v-if="apartment.status === 'created'">
                  <img
                    src="@/assets/images/icons-webp/added-by-user.webp"
                    :alt="TEXT_LABELS.userAddedWithoutModeration"
                    :title="TEXT_LABELS.userAddedWithoutModeration"
                    :id="`tooltip-target-added-by-user-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-added-by-user-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.userAddedWithoutModeration }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.status === 'unmoderated'">
                  <img
                    src="@/assets/images/icons-webp/no_moderator.webp"
                    :alt="TEXT_LABELS.objectAddedWithoutModeration"
                    :title="TEXT_LABELS.objectAddedWithoutModeration"
                    :id="`tooltip-target-no-moderator-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-no-moderator-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.objectAddedWithoutModeration }}
                  </b-tooltip>
                </li>

                <li v-if="isDraft">
                  <img
                    src="@/assets/images/icons-webp/icon_for_my_object.webp"
                    :alt="TEXT_LABELS.objectForPersonalUse"
                    :title="TEXT_LABELS.objectForPersonalUse"
                    :id="`tooltip-target-icon-for-my-object-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-icon-for-my-object-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.objectForPersonalUse }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_cashless_payment_available">
                  <img
                    src="@/assets/images/icons-webp/cashless_payments.webp"
                    :alt="TEXT_LABELS.cashlessPaymentInfo"
                    :title="TEXT_LABELS.cashlessPaymentInfo"
                    :id="`tooltip-target-used-for-cashless-payments-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-used-for-cashless-payments-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.cashlessPaymentInfo }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_used_for_advertising">
                  <img
                    src="@/assets/images/icons-webp/used_for_advertising.webp"
                    :alt="TEXT_LABELS.photoUsageForAds"
                    :title="TEXT_LABELS.photoUsageForAds"
                    :id="`tooltip-target-used-for-advertising-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-used-for-advertising-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.photoUsageForAds }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_seller_pays_the_commission">
                  <img
                    src="@/assets/images/icons-webp/seller_pays_the_commission.webp"
                    :alt="TEXT_LABELS.sellerPaysCommission"
                    :title="TEXT_LABELS.sellerPaysCommission"
                    :id="`tooltip-target-seller-pays-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-seller-pays-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.sellerPaysCommission }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_children_allowed">
                  <img
                    src="@/assets/images/icons-webp/is_children_allowed.webp"
                    :alt="TEXT_LABELS.childrenAllowed"
                    :title="TEXT_LABELS.childrenAllowed"
                    :id="`tooltip-target-is-children-allowed-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-is-children-allowed-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.childrenAllowed }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_foreigners_allowed">
                  <img
                    src="@/assets/images/icons-webp/is_foreigners_allowed.webp"
                    :alt="TEXT_LABELS.foreignersAllowed"
                    :title="TEXT_LABELS.foreignersAllowed"
                    :id="`tooltip-target-is-foreigners-allowed-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-is-foreigners-allowed-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.foreignersAllowed }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_pets_allowed">
                  <img
                    src="@/assets/images/icons-webp/is_pets_allowed.webp"
                    :alt="TEXT_LABELS.petsAllowed"
                    :title="TEXT_LABELS.petsAllowed"
                    :id="`tooltip-target-is-pets-allowed-${apartment.id}`"
                  />

                  <b-tooltip
                    :target="`tooltip-target-is-pets-allowed-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.petsAllowed }}
                  </b-tooltip>
                </li>
              </ul>
            </div>
          </div>

          <ul class="catalog-item-prop-container item-prop-container">
            <!-- Тайтл -->
            <li
              class="catalog-item-prop text-grey item-prop item-title firstLine-card"
              v-html="getTitle()"
            ></li>

            <!-- Тип -->
            <li class="catalog-item-prop item-prop text-grey">
              <template v-if="isCommerce">
                <span style="padding-right: 5px">
                  {{ apartment.rooms ? `${apartment.rooms}к` : '' }}
                </span>

                <div v-if="short_commerce_types[apartment.apt_type]">
                  {{ short_commerce_types[apartment.apt_type] }}

                  <svg
                    class="icon question-icon ml-2"
                    :id="`tooltip-commerce-types-${apartment.id}`"
                  >
                    <use
                      xlink:href="@/assets/icons/question.svg#question"
                    ></use>
                  </svg>

                  <b-tooltip
                    :target="`tooltip-commerce-types-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ commerce_types[apartment.apt_type] }}
                  </b-tooltip>
                </div>

                <div v-else>
                  {{ commerce_types[apartment.apt_type] }}
                </div>
              </template>

              <template v-else-if="isLand">
                {{ landPurposeLabel }}
              </template>

              <template v-else-if="isParking">
                <template v-if="parkingTypeLabel">
                  {{ parkingTypeLabel }}
                </template>
              </template>

              <template v-else>
                {{ material_labels[apartment.material_type] }}
                {{ apartment.is_angled ? 'Кутова /' : '' }}
                {{
                  apartment.type === 'house'
                    ? house_type[apartment.apt_type]
                    : ''
                }}
                {{ apartment.rooms ? `${apartment.rooms}к` : '' }}
                {{ apartment.apt_type === 'new_building' ? '/ нов' : '' }}
                {{ apartment.apt_type === 'room' ? '/ кімната' : '' }}
              </template>
            </li>

            <!-- Паркомісць / Матеріал (Гаражі) -->
            <template v-if="isParking">
              <!-- Паркомісць -->
              <li class="catalog-item-prop item-prop flex-column text-grey">
                <div v-if="apartment.vehicle_places">
                  {{ TEXT_LABELS.vehiclePlacesLabel }} -
                  {{ apartment.vehicle_places }}
                </div>
              </li>

              <!-- Матеріал-->
              <li class="catalog-item-prop item-prop flex-column text-grey">
                <div>Матеріал - {{ parkingMaterialTypeLabel }}</div>
              </li>
            </template>

            <!-- Поверховість -->
            <li
              v-if="!isLand && !isParking"
              class="catalog-item-prop item-prop text-grey"
            >
              {{ apartment.floor ? `${apartment.floor}/` : '' }}
              {{ apartment.floors }}

              <template v-if="isHouse">Поверховість</template>

              <template v-if="isCommerce">
                {{ material_labels[apartment.material_type] }}
              </template>
            </li>

            <!-- Кадастровий номер (Земельні ділянки) -->
            <li v-if="isLand" class="catalog-item-prop item-prop text-grey">
              {{ cadastralNumber }}
            </li>

            <!-- Площа -->
            <li
              class="catalog-item-prop item-prop text-grey"
              v-html="square"
            ></li>

            <!-- Довжина, ширина (Земельні ділянки) -->
            <li v-if="isLand" class="catalog-item-prop item-prop text-grey">
              <div>
                <div class="d-block">
                  Довж.
                  <span class="text-nowrap">
                    {{ getIntValue(apartment.length) || '--' }} м
                  </span>
                </div>

                <div class="d-block">
                  Шир.
                  <span class="text-nowrap">
                    {{ getIntValue(apartment.width) || '--' }} м
                  </span>
                </div>
              </div>
            </li>

            <!-- Ремонт -->
            <li
              v-if="!isLand && !isParking"
              class="catalog-item-prop item-prop text-grey"
            >
              {{ repairItem }}
            </li>

            <!-- Вартість -->
            <li class="catalog-item-prop item-prop text-grey">
              <span class="old-price">{{ apartment.price_old }}</span>

              <span>{{ getPrice() }}</span>

              <svg
                @click="(e) => e.stopPropagation()"
                class="icon question-icon ml-2"
                :id="`tooltip-target-price-desktop-${apartment.id}`"
              >
                <use xlink:href="@/assets/icons/pay_icon.svg#Capa_1"></use>
              </svg>

              <b-tooltip
                v-if="isDesktop"
                :target="`tooltip-target-price-desktop-${apartment.id}`"
                triggers="hover"
                placement="bottom"
              >
                {{ getPricePerMeter() }}
              </b-tooltip>
            </li>

            <!-- Телефон -->
            <li class="catalog-item-prop item-prop text-grey">
              <a :href="`tel:${getPhone(apartment.phone[0])}`">
                {{ getPhone(apartment.phone[0]) }}
              </a>

              <union-list
                v-if="apartment.unions.length"
                :list="apartment.unions"
              />
            </li>

            <!-- Labels -->
            <li
              class="catalog-item-prop item-prop catalog-item-prop-label"
              :id="`popover-target-${apartment.id}`"
            >
              <div
                class="btn-group-toggle form-checkbox-b"
                data-toggle="buttons"
                v-for="(item, index) in labels_display"
                :key="index + 'i'"
              >
                <div class="btn-group-toggle form-checkbox-b">
                  <label>
                    <input
                      v-show="false"
                      type="checkbox"
                      name="labels[]"
                      v-model="labels"
                      :value="item.key"
                    />

                    <icon
                      :name="`menu_inner_icon${index}`"
                      class="btn-menu-inner-icon"
                    />
                  </label>
                </div>
              </div>

              <b-popover
                :target="`popover-target-${apartment.id}`"
                triggers="hover"
                placement="top"
                custom-class="filter-modal dropdown-menu"
              >
                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div
                      class="btn-group-toggle form-checkbox-b"
                      data-toggle="buttons"
                      v-for="(item, index) in labels_display"
                      :key="index"
                    >
                      <label :class="`btn`">
                        <input
                          type="checkbox"
                          name="labels[]"
                          v-model="labels"
                          :value="item.key"
                        />

                        <icon
                          :name="`menu_inner_icon${index}`"
                          class="btn-menu-inner-icon"
                        />

                        <span>{{ item.label }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-marg"></div>
              </b-popover>
            </li>

            <!-- Актуально на: -->
            <li class="catalog-item-prop item-prop text-grey">
              Актуально на:
              {{
                apartment.updated != null ? apartment.updated.split(' ')[0] : ''
              }}
            </li>

            <!-- Джерело -->
            <li class="catalog-item-prop item-prop text-grey">
              {{ source_labels[apartment.source] }}
            </li>
          </ul>
        </div>

        <!-- Mobile -->
        <div
          v-else
          class="mob-catalog-item d-flex"
          @click="showModal(apartment.id, $event)"
        >
          <div class="mob-catalog-item-photo">
            <div
              class="hp-bg-container"
              :style="{
                backgroundImage: `url(${mainImg})`
              }"
            >
              <ul class="catalog-card__info-icons">
                <li v-if="apartment.status === 'created'">
                  <img
                    src="@/assets/images/icons-webp/added-by-user.webp"
                    :alt="TEXT_LABELS.userAddedWithoutModeration"
                    :title="TEXT_LABELS.userAddedWithoutModeration"
                    :id="`tooltip-target-added-by-user-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-added-by-user-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.userAddedWithoutModeration }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.status === 'unmoderated'">
                  <img
                    src="@/assets/images/icons-webp/no_moderator.webp"
                    :alt="TEXT_LABELS.objectAddedWithoutModeration"
                    :title="TEXT_LABELS.objectAddedWithoutModeration"
                    :id="`tooltip-target-no-moderator-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-no-moderator-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.objectAddedWithoutModeration }}
                  </b-tooltip>
                </li>

                <li v-if="isDraft">
                  <img
                    src="@/assets/images/icons-webp/icon_for_my_object.webp"
                    :alt="TEXT_LABELS.objectForPersonalUse"
                    :title="TEXT_LABELS.objectForPersonalUse"
                    :id="`tooltip-target-icon-for-my-object-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-icon-for-my-object-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.objectForPersonalUse }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_cashless_payment_available">
                  <img
                    src="@/assets/images/icons-webp/cashless_payments.webp"
                    :alt="TEXT_LABELS.cashlessPaymentInfo"
                    :title="TEXT_LABELS.cashlessPaymentInfo"
                    :id="`tooltip-target-used-for-cashless-payments-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-used-for-cashless-payments-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.cashlessPaymentInfo }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_used_for_advertising">
                  <img
                    src="@/assets/images/icons-webp/used_for_advertising.webp"
                    :alt="TEXT_LABELS.photoUsageForAds"
                    :title="TEXT_LABELS.photoUsageForAds"
                    :id="`tooltip-target-used-for-advertising-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-used-for-advertising-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.photoUsageForAds }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_seller_pays_the_commission">
                  <img
                    src="@/assets/images/icons-webp/seller_pays_the_commission.webp"
                    :alt="TEXT_LABELS.sellerPaysCommission"
                    :title="TEXT_LABELS.sellerPaysCommission"
                    :id="`tooltip-target-seller-pays-the-commission-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-seller-pays-the-commission-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.sellerPaysCommission }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_children_allowed">
                  <img
                    src="@/assets/images/icons-webp/is_children_allowed.webp"
                    :alt="TEXT_LABELS.childrenAllowed"
                    :title="TEXT_LABELS.childrenAllowed"
                    :id="`tooltip-target-is-children-allowed-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-is-children-allowed-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.childrenAllowed }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_foreigners_allowed">
                  <img
                    src="@/assets/images/icons-webp/is_foreigners_allowed.webp"
                    :alt="TEXT_LABELS.foreignersAllowed"
                    :title="TEXT_LABELS.foreignersAllowed"
                    :id="`tooltip-target-is-foreigners-allowed-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-is-foreigners-allowed-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.foreignersAllowed }}
                  </b-tooltip>
                </li>

                <li v-if="apartment.is_pets_allowed">
                  <img
                    src="@/assets/images/icons-webp/is_pets_allowed.webp"
                    :alt="TEXT_LABELS.petsAllowed"
                    :title="TEXT_LABELS.petsAllowed"
                    :id="`tooltip-target-pets-allowed-mobile-${apartment.id}`"
                    @click="(e) => e.stopPropagation()"
                  />

                  <b-tooltip
                    :target="`tooltip-target-pets-allowed-mobile-${apartment.id}`"
                    triggers="hover"
                    placement="bottom"
                  >
                    {{ TEXT_LABELS.petsAllowed }}
                  </b-tooltip>
                </li>
              </ul>
            </div>
          </div>

          <div class="mob-catalog-info-container w-100 d-flex flex-column">
            <div class="mob-catalog-title mb-1">
              <span class="inner-text" v-html="getTitle()"></span>

              <span
                v-if="apartment.is_archive === '1'"
                class="moving-to-archive"
              >
                Переходить до архіву
              </span>

              <kebab-menu
                :altText="apartment.house_number"
                :popoverId="`mob-popover-target-${apartment.id}`"
                ref="kebabMenu"
              >
                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div
                      class="btn-group-toggle form-checkbox-b"
                      data-toggle="buttons"
                      v-for="(item, index) in labels_display"
                      :key="index"
                    >
                      <label class="kebab-menu-item">
                        <input
                          type="checkbox"
                          name="labels[]"
                          v-model="labels"
                          :value="item.key"
                        />

                        <span class="kebab-item-label">
                          <img
                            :src="icons[index]"
                            alt="action icon"
                            class="kebab-item-icon"
                          />

                          {{ item.label }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </kebab-menu>
            </div>

            <ApartmentTable
              :apartment="apartment"
              :material_labels="material_labels"
              :house_type="house_type"
              :square="square"
              :repairItem="repairItem"
              :short_commerce_types="short_commerce_types"
              :commerce_types="commerce_types"
              :pricePerMeter="getPricePerMeter()"
            />

            <div class="catalog-card__arrow-down">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="243"
                height="20"
                viewBox="0 0 243 20"
                style="fill: transparent"
              >
                <path
                  d="M117.242 8.03372L122.764 12.5887L128.11 7.82894"
                  stroke="#00D2CD"
                  stroke-width="1.1646"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- Details -->
      <div v-if="modal === apartment.id" class="product-modal item-modal">
        <transition v-if="isDesktop" name="fade-slide">
          <simplebar class="product-modal-inner scrollbar-external-y">
            <catalog-card-info
              :apartment="apartment"
              :edit="edit"
              class="catalog-card-info"
              @edit-object="$emit('edit-object', $event)"
            />
          </simplebar>
        </transition>

        <div v-else class="mob-container product-modal-inner">
          <catalog-card-info-mobile
            :apartment="apartment"
            :edit="edit"
            :archived="apartment.is_archive === '1'"
            class="catalog-card-info"
            @edit-object="$emit('edit-object', $event)"
          >
          </catalog-card-info-mobile>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {
  ESTATE_TYPES,
  TEXT_LABELS,
  PARKING_TYPES,
  PARKING_MATERIAL_TYPES
} from '@/helpers/constants';

import simplebar from 'simplebar-vue';
import LabelsMixin from '@/mixins/LabelsMixin';
import ResponsiveMixin from '@/mixins/responsive';
import api from '@/api';
import { getHiddenPhone } from '@/helpers/formats';
import { getIntegerValue } from '@/utils/get-integer-value';
import ApartmentTable from './ApartmentTable.vue';
import CatalogCardInfoMobile from './CatalogCardInfoMobile.vue';
import KebabMenu from '@/elements/kebab-menu';
import icon0 from '@/assets/object-actions/good.svg';
import icon1 from '@/assets/object-actions/bad.svg';
import icon2 from '@/assets/object-actions/smile.svg';
import icon3 from '@/assets/object-actions/force.svg';
import icon4 from '@/assets/object-actions/telescope.svg';
import icon5 from '@/assets/object-actions/plus.svg';
import icon6 from '@/assets/object-actions/minus.svg';
import icon7 from '@/assets/object-actions/sun.svg';
import icon8 from '@/assets/object-actions/phone.svg';
import icon9 from '@/assets/object-actions/stone.svg';
import UnionList from '@/elements/union-list/union-list.vue';

import noImage from '@/assets/images/no-image.webp';

export default {
  name: 'CatalogCard',
  mixins: [LabelsMixin, ResponsiveMixin],
  props: {
    apartment: Object,
    edit: Boolean,
    page: {
      type: String,
      default: 'base'
    }
  },
  components: {
    UnionList,
    CatalogCardInfo: () => import('./CatalogCardInfo.vue'),
    CatalogCardInfoMobile,
    simplebar,
    ApartmentTable,
    KebabMenu
  },
  data() {
    return {
      modal: null,
      labels: this.apartment.labels,
      index: null,
      apartmentObject: this.apartment,
      square: '',
      icons: {
        0: icon0,
        1: icon1,
        2: icon2,
        3: icon3,
        4: icon4,
        5: icon5,
        6: icon6,
        7: icon7,
        8: icon8,
        9: icon9
      },
      TEXT_LABELS
    };
  },
  watch: {
    labels() {
      this.updateLabels();
    }
  },
  computed: {
    imagesCollection() {
      const items = this.apartmentObject.images.map((a) => a.img_obj);
      return items;
    },
    repairItem() {
      const isItem = this.choises_label.find(
        (a) => a.label === this.apartmentObject.repair
      );
      if (isItem) return isItem.name;
      return undefined;
    },
    labels_display() {
      const labels_arr = this.apartment.labels ? this.apartment.labels : [];
      const returnLabels = [];
      for (const label in this.user_labels) {
        const t = {};
        t.label = this.user_labels[label];
        t.key = label;
        t.active = labels_arr.indexOf(label) > 0 ? 'active' : '';
        returnLabels.push(t);
      }
      return returnLabels;
    },
    images: () => {
      return [{ thumb: require('../../assets/images/no-image.webp') }];
    },
    carText: () => [
      '<svg viewBox="0 0 22 22" class="owl-nav-left svg-icon svg-fill"><path d="M21 5.6L11 16.4 1 5.6h20z"></path></svg>',
      '<svg viewBox="0 0 22 22" class="owl-nav-right svg-icon svg-fill"><path d="M21 5.6L11 16.4 1 5.6h20z"></path></svg>'
    ],
    mainImg() {
      const imageUrl =
        this.apartment.images && this.apartment.images.length > 0
          ? this.apartment.images[0].img_obj
          : noImage;

      return imageUrl;
    },
    isFlat() {
      return this.apartment.type === ESTATE_TYPES.flat;
    },
    isHouse() {
      return this.apartment.type === ESTATE_TYPES.house;
    },
    isCommerce() {
      return this.apartment.type === ESTATE_TYPES.commerce;
    },
    isLand() {
      return this.apartment.type === ESTATE_TYPES.land;
    },
    isParking() {
      return this.apartment.type === ESTATE_TYPES.parking;
    },
    landPurposeLabel() {
      return this.land_types[this.apartment.purpose];
    },
    cadastralNumber() {
      return this.apartment.cadastral_number
        ? this.apartment.cadastral_number
        : '––––––––––:––:–––:––––';
    },
    isDraft() {
      return this.apartment.status === 'draft';
    },
    parkingTypeLabel() {
      return this.apartment.parking_type
        ? PARKING_TYPES[this.apartment.parking_type]
        : '';
    },
    parkingMaterialTypeLabel() {
      return this.apartment.material
        ? PARKING_MATERIAL_TYPES[this.apartment.material]
        : 'не вказано';
    }
  },
  created() {
    this.square = this.getFormattedSquare();
  },
  methods: {
    getPhone(phone) {
      if (
        this.apartment.is_archive == '2' &&
        this.apartment.source === 'owner'
      ) {
        return getHiddenPhone(phone);
      }
      return phone;
    },
    showModal(id, event) {
      if (
        this.$refs.kebabMenu &&
        this.$refs.kebabMenu.$el.contains(event.target)
      ) {
        return;
      }

      const idTooltipMobile = `tooltip-target-price-mobile-${id}`;
      const idTooltipMobileModerator = `tooltip-target-no-moderator-mobile-${id}`;
      const idTooltipMobileAdvertising = `tooltip-target-used-for-advertising-mobile-${id}`;
      const idTooltipMobileCommission = `tooltip-target-seller-pays-the-commission-mobile-${id}`;
      let path = event.path || (event.composedPath && event.composedPath());

      if (
        !path.some(
          ({ id }) =>
            id === idTooltipMobile ||
            id === idTooltipMobileModerator ||
            id === idTooltipMobileAdvertising ||
            id === idTooltipMobileCommission
        ) &&
        this.modal !== id
      ) {
        this.modal = id;
      } else {
        this.modal = null;
      }
    },
    updateLabels() {
      const payload = {
        data: this.labels,
        params: { id: this.apartment.id }
      };

      api.updateApartmentLabels(payload);
    },
    getPrice() {
      const usd = this.apartment.business_type !== 'rent' ? '$' : '';
      const grn = this.apartment.business_type === 'rent' ? 'грн' : '';

      return usd + this.apartment.price + grn;
    },
    getPricePerMeter() {
      if (!this.apartment.square_common) {
        return 'Невідома площа для розрахунку';
      }
      const usd = this.apartment.business_type !== 'rent' ? '$' : '';
      const grn = this.apartment.business_type === 'rent' ? 'грн' : '';

      return (
        'Ціна за кв. метр ' +
        usd +
        (
          this.apartment.price / parseFloat(this.apartment.square_common)
        ).toFixed(0) +
        grn
      );
    },
    getTitle() {
      let title = '';
      let showDistrict = true;
      let prefix = '';

      if (this.apartment.microdistrict && this.apartment.microdistrict.name) {
        let showFullMicrodistrict = false;

        if (this.apartment.microdistrict.name.search(/(верх)|(низ)/) !== -1) {
          showFullMicrodistrict = true;
        }

        title +=
          this.apartment.residential_complex && !showFullMicrodistrict
            ? `<span>${this.apartment.microdistrict.name.replace(
                /\((.+?)\)/g,
                ''
              )} </span><br />
              `
            : `<span>${this.apartment.microdistrict.name} </span><br />
            `;

        showDistrict = false;
      }

      if (this.apartment.street && this.apartment.street.name) {
        title += this.apartment.residential_complex
          ? `${this.apartment.street.name.replace(/\((.+?)\)/g, '')} `
          : `${this.apartment.street.name}`;

        if (this.apartment.house_number) {
          title += ` ${this.apartment.house_number}`;
        }

        showDistrict = false;
      }

      if (
        showDistrict &&
        this.apartment.district &&
        this.apartment.city.is_city
      ) {
        title += this.apartment.district.name;
      }

      if (this.apartment.house_letter) {
        title += ` ${this.apartment.house_letter}`;
      }

      if (this.apartment.house_hull && this.apartment.house_hull.length > 0) {
        title += ` , корп. ${this.apartment.house_hull}`;
      }

      if (this.apartment.residential_complex) {
        prefix += `ЖК ${this.apartment.residential_complex.name} </br>`;
      }

      //  ЖК, ТЦ, ТРЦ, БЦ
      if (this.apartment.building) {
        prefix += `${this.apartment.building.name} </br>`;
      }

      //  Гаражний кооператив
      if (this.apartment.cooperative) {
        prefix += `${this.apartment.cooperative.name} </br>`;
      }

      title = `<span>${prefix}</span> ${title}`;

      if (!this.apartment.city.is_city && this.apartment.district) {
        title += ` ${this.apartment.district.name}`;
      }

      return `<div class="mobile-card-title">${title}</div>`;
    },
    getIntValue(value) {
      return getIntegerValue(value) || 0;
    },
    getFormattedSquare() {
      const squareMeter = 'м<sup>2</sup>';
      const map = [];

      if (this.isParking) {
        const parkingArea =
          parseFloat(this.apartment.square_common) ||
          parseFloat(this.apartment.square_area);

        if (parkingArea) {
          map.push(`${parkingArea} ${squareMeter}`);
        }
      } else {
        const areas = [
          { value: this.apartment.square_common, unit: squareMeter },
          { value: this.apartment.square_living, unit: squareMeter },
          { value: this.apartment.square_kitchen, unit: squareMeter },
          { value: this.apartment.square_area, unit: 'сот.' }
        ];

        areas.forEach(({ value, unit }) => {
          const parsedValue = parseFloat(value);

          if (parsedValue) {
            map.push(`${parsedValue} ${unit}`);
          }
        });
      }

      return map.join(', ');
    }
  }
};
</script>

<style lang="sass">
.catalog-card
  display: block

  &__arrow-down
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    text-align: center

    +media-breakpoint-up(md)
      display: none

  &__img-wrap
    height: 9rem
    background: url('~@/assets/images/no-image.webp') no-repeat center / cover
    cursor: pointer

  &__img
    display: block
    width: 100%
    height: 100%
    background-position: center
    background-size: cover
    background-repeat: no-repeat

  &__info-icons
    list-style: none
    display: flex
    flex-wrap: wrap
    justify-content: flex-end
    gap: 0.375rem
    margin: 0
    padding: 0.25rem

    > li
      flex: 0 0 var(--catalog-card-info-icon-size)
      width: var(--catalog-card-info-icon-size)
      height: var(--catalog-card-info-icon-size)
      cursor: pointer

      img
        display: block
        width: 100%
        max-width: 100%
        height: 100%
        max-height: 100%
        object-fit: contain

.old-price
  text-decoration: line-through
  margin-right: 5px
.catalog-item, .product-modal
    font-size: 14px
.btn-group-toggle:nth-child(2) .btn-menu-inner-icon
    transform: rotate(180deg)

.btn-group-toggle
    padding: 1px
    .btn-menu-inner-icon
        width: 12px
        height: 12px

.catalog-item-prop-label
    padding-top: 5px

.btn-outer-label
    padding: 2px
    .btn-menu-inner-icon
        max-height: 11px
        max-width: 11px

.copy_string_active
    color: green

.catalog-carousel
    height: 285px
    font-size: 14px
    .owl-carousel .owl-stage
        height: inherit
        display: flex!important

    .owl-carousel .owl-item
        height: inherit
        width: 100%

        img
            object-fit: contain
            height: 100%

        > div
            height: 285px

    +media-breakpoint-up(md)
        height: 500px

        .owl-carousel .owl-item
            > div
                height: 500px
.btn-red.btn-filter
    cursor: pointer
    :hover
        color: #fff

.img-no-moderator
    width: 30px !important
    height: 30px !important
    float: right
    margin-top: 3px
    margin-left: 3px

.mobile-card-title
  font-size: 15px
  font-weight: 600
  span
    font-size: 13px
    font-weight: 300

.moving-to-archive
  background: red
  padding: 0 4px
  border-radius: 4px

@media (max-width: 575px)
    .catalog-carousel
        height: 320px
@media (max-width: 991px)
  .card-block
    border: 1px solid rgba(46,41,62,0.32)
  .card-block--arhived
    background-color: rgb(114 140 166)
    .product-modal
      background-color: rgb(114 140 166)

@media (min-width: 991px)
  .card-block--arhived
    position: relative

    .catalog-item-prop,
    .catalog-card-info
      padding: 0 30px

      &:after
        content: ''
        position: absolute
        z-index: 99
        background-color: rgba(255, 255, 255, 0.2)

    .catalog-card-info
      &::after
        left: -30px
        right: -30px
        top: -20px
        bottom: -20px

    .catalog-item-prop
      &::after
        left: 0
        right: 0
        top: 0
        bottom: 0
</style>
