<template>
  <div
    class="three-container"
    ref="rendererContainer"
    @click="handleClick"
  ></div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import ResponsiveMixin from '@/mixins/responsive';

export default {
  name: 'StarPanelScene',
  mixins: [ResponsiveMixin],
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
      animationId: null,
      model: null,
      shadowPlane: null,
      targetRotation: { x: 0, y: 0 },
      currentRotation: { x: 0, y: 0 },
      velocity: { x: 0, y: 0 },
      clickCount: 0, // Счётчик кликов
      audio: new Audio(), // Аудио объект
      isAudioPlaying: false // Флаг для проверки, воспроизводится ли аудио
    };
  },
  mounted() {
    this.initThree();
    this.animate();
    window.addEventListener('mousemove', this.handleMove);
    window.addEventListener('touchmove', this.handleMove);
    window.addEventListener('resize', this.onWindowResize);

    if (window.matchMedia('(max-width: 767px)').matches) {
      this.startMobileAnimation();
    }
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.handleMove);
    window.removeEventListener('touchmove', this.handleMove);
    window.removeEventListener('resize', this.onWindowResize);
    if (this.animationId) cancelAnimationFrame(this.animationId);
    if (this.renderer) {
      this.renderer.dispose();
      this.renderer.forceContextLoss();
    }
    if (this.mobileAnimationInterval) {
      clearInterval(this.mobileAnimationInterval);
    }
  },
  methods: {
    startMobileAnimation() {
      this.mobileAnimationInterval = setInterval(() => {
        this.targetRotation.x = Math.sin(Date.now() * 0.001) * 0.1; // Плавные колебания по X
        this.targetRotation.y = Math.cos(Date.now() * 0.001) * 0.1; // Плавные колебания по Y
      }, 16); // 60 FPS (1000ms / 60 ≈ 16ms)
    },

    initThree() {
      const FRUSTUM_SIZE = 4.75;
      const CAM_POS = { x: 4.8, y: 4.75, z: 6 };
      const AMBIENT_INTENSITY = 1;
      const DIR_LIGHT_INTENSITY = 3;
      const DIR_LIGHT_POS = { x: -10, y: 30, z: 10 };
      const SHADOW_PLANE_Y = -1.3;
      const SHADOW_PLANE_Z = 1.3;
      const SHADOW_PLANE_OPACITY = 0.29;
      const MODEL_ROT = { x: 0, y: 0, z: 0 };

      this.scene = new THREE.Scene();

      const width = this.$refs.rendererContainer.clientWidth;
      const height = this.$refs.rendererContainer.clientHeight;
      const aspect = width / height;

      this.camera = new THREE.OrthographicCamera(
        (FRUSTUM_SIZE * aspect) / -2,
        (FRUSTUM_SIZE * aspect) / 2,
        FRUSTUM_SIZE / 2,
        FRUSTUM_SIZE / -2,
        0.1,
        1000
      );
      this.camera.position.set(CAM_POS.x, CAM_POS.y, CAM_POS.z);
      this.camera.lookAt(0, 0, 0);

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(width, height);
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.setClearColor(0x000000, 0);

      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

      this.$refs.rendererContainer.appendChild(this.renderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.1;
      this.controls.enableRotate = false;
      this.controls.enableZoom = false;
      this.controls.enablePan = false;

      const exrLoader = new EXRLoader();
      exrLoader.load('/models/logo/StudioWhiteUmbrella.exr', (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        this.scene.environment = texture;
        this.scene.background = null;
      });

      const ambientLight = new THREE.AmbientLight(0xffffff, AMBIENT_INTENSITY);
      this.scene.add(ambientLight);

      const dirLight = new THREE.DirectionalLight(
        0xffffff,
        DIR_LIGHT_INTENSITY
      );
      dirLight.position.set(DIR_LIGHT_POS.x, DIR_LIGHT_POS.y, DIR_LIGHT_POS.z);
      dirLight.castShadow = true;
      dirLight.shadow.mapSize.width = 2048;
      dirLight.shadow.mapSize.height = 2048;
      dirLight.shadow.camera.left = -10;
      dirLight.shadow.camera.right = 10;
      dirLight.shadow.camera.top = 10;
      dirLight.shadow.camera.bottom = -10;
      dirLight.shadow.camera.near = 3;
      dirLight.shadow.camera.far = 50;
      dirLight.shadow.radius = 4;
      this.scene.add(dirLight);

      const planeGeo = new THREE.PlaneGeometry(50, 50);
      const planeMat = new THREE.ShadowMaterial({
        color: 0x000000,
        opacity: SHADOW_PLANE_OPACITY
      });
      this.shadowPlane = new THREE.Mesh(planeGeo, planeMat);
      this.shadowPlane.receiveShadow = true;
      this.shadowPlane.rotation.x = -Math.PI / 2;
      this.shadowPlane.position.y = SHADOW_PLANE_Y;
      this.shadowPlane.position.z = SHADOW_PLANE_Z;
      this.scene.add(this.shadowPlane);

      const gltfLoader = new GLTFLoader();
      gltfLoader.load('/models/logo/logo.gltf', (gltf) => {
        this.model = gltf.scene;

        this.model.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true;
            if (child.material) {
              const oldMat = child.material;
              const newMat = new THREE.MeshLambertMaterial({
                color: oldMat.color
                  ? oldMat.color.clone()
                  : new THREE.Color(0xffffff),
                map: oldMat.map || null
              });
              child.material = newMat;
            }
          }
        });

        this.scene.add(this.model);
      });
    },

    animate() {
      this.animationId = requestAnimationFrame(this.animate);

      if (this.model) {
        this.currentRotation.x +=
          (this.targetRotation.x - this.currentRotation.x) * 0.03;
        this.currentRotation.y +=
          (this.targetRotation.y - this.currentRotation.y) * 0.03;

        this.model.rotation.x = this.currentRotation.x;
        this.model.rotation.y = this.currentRotation.y;
      }

      if (this.controls) this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },

    onWindowResize() {
      if (!this.camera || !this.renderer) return;

      const width = this.$refs.rendererContainer.clientWidth;
      const height = this.$refs.rendererContainer.clientHeight;
      const aspect = width / height;

      const FRUSTUM_SIZE = 5.7;
      this.camera.left = (FRUSTUM_SIZE * aspect) / -2;
      this.camera.right = (FRUSTUM_SIZE * aspect) / 2;
      this.camera.top = FRUSTUM_SIZE / 2;
      this.camera.bottom = FRUSTUM_SIZE / -2;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(width, height);
    },

    handleMove(e) {
      if (!this.model || this.isMobile) return;

      let clientX, clientY;

      if (e.touches) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else {
        clientX = e.clientX;
        clientY = e.clientY;
      }

      const x = clientX / window.innerWidth - 0.5;
      const y = clientY / window.innerHeight - 0.5;

      this.velocity.x = (x * 1.5 - this.targetRotation.y) * 0.2;
      this.velocity.y = (y * 0.5 - this.targetRotation.x) * 0.2;

      this.targetRotation.y = x * 0.8;
      this.targetRotation.x = y * 0.2;
    },

    handleClick() {
      if (this.isAudioPlaying) {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.isAudioPlaying = false;
        return;
      }

      this.clickCount++;

      let audioSrc = '';
      if (this.clickCount === 1 || this.clickCount === 2) {
        audioSrc = '/models/logo/intro.mp3';
      } else if (this.clickCount === 3) {
        audioSrc = '/models/logo/introFull.mp3';
      } else {
        this.clickCount = 0;
        return;
      }

      this.audio = new Audio(audioSrc); // Создаем новый объект Audio
      this.audio.volume = 0.4;
      this.audio.play();
      this.isAudioPlaying = true;

      this.audio.onended = () => {
        this.isAudioPlaying = false; // Сбрасываем флаг после завершения
      };
    }
  }
};
</script>

<style scoped>
.three-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
</style>
