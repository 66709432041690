<template>
  <div class="login-form-container">
    <ResetPassword v-if="$route.path === '/reset-password'" />

    <LoginForm v-else />
  </div>
</template>

<script>
import LoginForm from '@/components/auth/LoginForm.vue';
import ResetPassword from '@/pages/ResetPassword.vue';

export default {
  name: 'LoginFormContainer',
  components: {
    LoginForm,
    ResetPassword
  }
};
</script>
