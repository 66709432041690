<template>
  <div
    class="login-form-wrap"
    :class="{ _loading: $store.getters.authStatus === 'loading' }"
  >
    <div
      v-if="$store.getters.authStatus === 'loading'"
      class="login-form-wrap__loading"
    >
      <img src="@/assets/images/loading.gif" alt="Loading ..." />
    </div>

    <form
      class="login-form-wrap__form login-form"
      @submit.prevent="resetPassword"
    >
      <div v-if="!statusPhone.phone" class="login-form__group">
        <div class="login-form__input-wrap">
          <icon name="required_icon" class="login-form__required" />

          <PhoneInput v-model="phone" mode="landing" />
        </div>

        <div class="login-form__disclaimer">
          <span>Введіть Ваш номер телефону</span>
        </div>
      </div>

      <div v-if="statusPhone.phone && !statusCode" class="login-form__group">
        <div class="login-form__input-wrap">
          <icon name="required_icon" class="login-form__required" />

          <div class="login-form__input">
            <input v-model="code" maxlength="4" placeholder="Код" />
          </div>
        </div>
      </div>

      <div v-if="statusCode" class="login-form__group">
        <div class="login-form__input-wrap">
          <icon name="required_icon" class="login-form__required" />

          <PasswordInput
            v-model="password"
            mode="landing"
            placeholder="Пароль"
          />
        </div>

        <div class="login-form__disclaimer">
          <span>Введіть новий пароль щонайменше 6 символів</span>
        </div>
      </div>

      <div v-if="errorsAuth.phone_error" class="login-form__error">
        {{ errorsAuth.phone_error }}
      </div>

      <div v-if="errorsAuth.phone" class="login-form__error">
        {{ errorsAuth.phone[0] }}
      </div>

      <div v-if="errorsAuth.password" class="login-form__error">
        {{ errorsAuth.password[0] }}
      </div>

      <div v-if="errorsAuth.username" class="login-form__error">
        {{ errorsAuth.username[0] }}
      </div>

      <div v-if="errorsAuth.non_field_errors" class="login-form__error">
        {{ errorsAuth.non_field_errors[0] }}
      </div>

      <div v-if="errorsAuth.error_codes" class="login-form__error">
        {{ errorsAuth.error_codes }}
      </div>

      <div v-if="!statusPhone.phone" class="login-form__group">
        <button
          class="login-form__submit-btn"
          :class="phone.length <= PHONE_NUMBER_MIN_LENGTH ? 'disabled ' : ''"
          :disabled="phone.length <= PHONE_NUMBER_MIN_LENGTH"
          @click.prevent="sendPhone"
        >
          Отримати код
        </button>
      </div>

      <div v-if="statusPhone.phone && !statusCode" class="login-form__group">
        <button class="login-form__submit-btn" @click.prevent="confirmCode">
          Підтвердити код
        </button>
      </div>

      <div v-if="statusCode" class="login-form__group">
        <button type="submit" class="login-form__submit-btn">
          Оновити пароль
        </button>
      </div>

      <span v-if="!$v.phone.numeric" class="login-form__error">
        У поле "телефон" можна вводити тільки числові значення
      </span>
    </form>

    <div class="text-center">
      <router-link to="/login" class="login-form-wrap__forgot-link">
        ← Авторизація
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import PasswordInput from '@/components/forms/PasswordInput.vue';

export default {
  name: 'ResetPassword',
  components: {
    PhoneInput,
    PasswordInput
  },
  data() {
    return {
      countryCode: '+380',
      phone: '',
      password: '',
      code: '',
      PHONE_NUMBER_MIN_LENGTH: 8
    };
  },
  validations: {
    phone: {
      required,
      numeric,
      minLength: minLength(7)
    },
    code: {
      required,
      numeric,
      minLength: minLength(4)
    }
  },
  computed: {
    ...mapState({
      errorsAuth: (state) => state.auth.errorsAuth,
      statusCode: (state) => state.auth.statusCode,
      statusPhone: (state) => state.auth.statusPhone
    })
  },
  beforeMount() {
    this.code = '';
  },
  methods: {
    ...mapActions(['handleSendPhone', 'handleSendCode', 'handleResetPassword']),
    ...mapMutations(['RESET_REGISTER']),
    sendPhone() {
      const phoneSend = `0${this.phone}`;

      const payload = {
        path: 'sms_restore',
        phone: phoneSend.replace(/\s+/g, '')
      };

      this.handleSendPhone(payload);
    },
    confirmCode() {
      if (this.code.length >= 4 && this.statusPhone.phone == true) {
        const payload = {
          code: this.code,
          path: 'sms_restore'
        };

        this.handleSendCode(payload);
      }
    },
    resetPassword() {
      const data = {
        phone: `0${this.phone}`,
        password: this.password
      };

      this.handleResetPassword(data);
    }
  },
  destroyed() {
    this.RESET_REGISTER();
  }
};
</script>
