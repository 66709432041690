<template>
  <section class="landing-hero">
    <div class="landing-hero__container landing-container">
      <div class="landing-hero__wrap">
        <div class="landing-hero__row">
          <div class="landing-hero__col landing-hero__col--text">
            <div class="landing-hero__text">
              <h2 class="landing-hero__title" data-aos="fade-right">
                База перевіреної нерухомості
              </h2>

              <div
                class="landing-hero__description"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <p>
                  <strong>EasyBase</strong> — база для ріелторів з перевіреними
                  об'єктами. Ми особисто телефонуємо за кожним оголошенням і
                  підтверджуємо актуальність. <br />
                  Доступ лише для професіоналів.
                </p>
              </div>

              <div
                class="landing-hero__form-wrap"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <LoginFormContainer />
              </div>
            </div>
          </div>

          <div class="landing-hero__col landing-hero__col--model">
            <div class="landing-hero__model-wrap">
              <div
                ref="modelWrapper"
                class="landing-hero__model-wrap-inner"
                @click="enableInteraction"
              >
                <ThreeDLogo ref="threeDModel" />
              </div>
            </div>

            <button
              class="landing-hero__support-btn"
              v-b-modal.add-feedback-popup
            >
              <img
                src="@/assets/images/icons-svg/chat.svg"
                alt="Easybase Support"
                loading="lazy"
                width="18"
                height="18"
              />

              <span>Підтримка</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoginFormContainer from '@/components/auth/LoginFormContainer.vue';
import ThreeDLogo from './ThreeDLogo.vue';
import ResponsiveMixin from '@/mixins/responsive';

export default {
  name: 'LandingHeroSection',
  mixins: [ResponsiveMixin],
  components: {
    LoginFormContainer,
    ThreeDLogo
  },
  data() {
    return {
      modelWrapperEl: null,
      clickCount: 0
    };
  },
  mounted() {
    if (this.isMobile) {
      this.modelWrapperEl = this.$refs.modelWrapper;
      document.addEventListener('click', this.disableInteraction);
    }
  },
  beforeDestroy() {
    if (this.isMobile) {
      this.modelWrapperEl = this.$refs.modelWrapper;
      document.removeEventListener('click', this.disableInteraction);
    }
  },
  methods: {
    enableInteraction(e) {
      if (!this.isMobile) return;

      e.stopPropagation();

      if (this.modelWrapperEl) {
        this.modelWrapperEl.classList.add('_active');

        const modelEl = this.$refs.threeDModel.$el;
        if (modelEl && this.clickCount === 0) {
          modelEl.click();
          this.clickCount++;
        }
      }
    },
    disableInteraction(e) {
      if (!this.isMobile) return;

      if (this.modelWrapperEl && !this.modelWrapperEl.contains(e.target)) {
        this.modelWrapperEl.classList.remove('_active');
      }
    }
  }
};
</script>

<style lang="sass">
.landing-hero
  overflow: hidden

  &__wrap
    padding: 3rem 0 3.25rem

    @include media-breakpoint-down(md)
      padding: calc(var(--landing-header-height) + 2.5rem) 0 2.5rem

  &__row
    --col-width: 100%
    --col-gap: 2.25rem

    display: flex
    flex-wrap: wrap
    align-items: center

    @include media-breakpoint-up(md)
      --col-width: 50%
      --col-gap: 3rem

  &__col
    flex: 0 0 var(--col-width)
    max-width: var(--col-width)

  &__text
    position: relative
    z-index: 19

  &__title
    margin: 0 auto
    max-width: 29.875rem
    font-size: 2.5rem
    font-weight: 700
    line-height: 1
    text-wrap: balance
    text-align: center

    @include media-breakpoint-up(md)
      margin-left: 0
      font-size: 2.8125rem
      text-align: left

    &:not(:last-child)
      margin-bottom: 1.5rem

  &__description
    max-width: 34.375rem
    font-size: 1.5rem
    font-weight: 300
    line-height: 1.4

    @include media-breakpoint-down(md)
      margin: 0 auto
      max-width: 24rem
      font-size: 1rem
      text-align: center

    &:not(:last-child)
      margin-bottom: 1.75rem

  &__form-wrap
    margin: 0 auto
    max-width: 17rem

    @include media-breakpoint-up(md)
      margin-left: 0

  &__model-wrap
    @include media-breakpoint-up(lg)
      position: relative
      left: -10%
      width: 130%

  &__model-wrap-inner
    position: relative
    padding-bottom: 100%

    &:not(._active) *
      @include media-breakpoint-down(md)
        pointer-events: none

    .three-container
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%

  &__support-btn
    position: relative
    z-index: 29
    display: flex
    align-items: center
    justify-content: center
    gap: 0.75rem
    margin-left: auto
    border: none
    border-radius: 5px
    width: 198px
    height: 52px
    background: linear-gradient(to right, #305367, transparent)
    color: var(--color-white)
    font-size: 1rem
    font-weight: 300
    transition: var(--transition-duration-primary)

    @include media-breakpoint-down(md)
      position: fixed
      z-index: 99
      right: 1.25rem
      bottom: 1.25rem
      background: linear-gradient(to right, #305367, #5D3550)

    &:not(:disabled)
      cursor: pointer

      &:hover
        box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.2)
</style>
