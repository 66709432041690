<template>
  <div :class="$bem()">
    <div class="landing-container">
      <div :class="$bem('inner')">
        <h2 :class="$bem('title')" data-aos="fade-up">
          Часті питання про EasyBase
        </h2>

        <accordion :items="items"></accordion>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import accordion from '@/elements/accordion';

export default {
  components: { accordion },
  name: 'landing-questions-screen',
  mixins: [component],
  data() {
    return {
      items: [
        {
          title: 'Чому обирають саме нас?',
          content:
            'На ринку існує безліч баз нерухомості, але багато з них просто автоматично збирають інформацію з Інтернету й додають її на свої сайти. Ми відрізняємось від них, оскільки ми не просто перекладаємо дані, а перевіряємо їх актуальність і забезпечуємо повну та достовірну інформацію. Крім того, ми додаємо дані не лише від власників, а й від колег-ріелторів, що дозволяє нам мати значно ширшу інформацію про ринок нерухомості!'
        },
        {
          title: 'Звідки ми отримуємо інформацію?',
          content:
            'Ми стежимо за всіма провідними рекламними платформами та переглядаємо понад 200 спеціалізованих груп у Viber. Крім того, більш ніж 26% об`єктів надходить до нас як первинне джерело завдяки вашим колегам.'
        },
        {
          title: 'Чи можна відправити кілька об`єктів одночасно?',
          content:
            'Так, для цього вам потрібно відкрити особистий кабінет на EasyHata, відфільтрувати необхідний список об`єктів, скопіювати посилання і надіслати його клієнту.'
        },
        {
          title: 'Які типи нерухомості доступні у базі?',
          content:
            'На сьогоднішній день у нашій базі доступні квартири, будинки та комерційна нерухомість. Найближчим часом ми також плануємо додати земельні ділянки.'
        },
        {
          title: 'Чому в базі є один об`єкт від різних колег?',
          content:
            'Перший об`єкт ми додаємо за нашою особистою ініціативою, а інший - лише якщо користувач сам додає їх до бази, але зазначаю - лише за умови, що у нього є прямий доступ до власника і він з іншої агенції нерухомості. Ми робимо це, щоб ви мали можливість обирати, з ким саме з ваших колег ви бажаєте співпрацювати, а не отримувати від нас примус до співпраці з одним-єдиним колегою.'
        },
        {
          title:
            'Як мені змінити контакти об`єкту власника на свої, якщо я підписав(ла) ексклюзив?',
          content:
            'Для цього достатньо проінформувати підтримку сайту, ми підтвердимо інформацію та замінимо контакти на ваші! Якщо у мене усний ексклюзив, а не письмовий, чи будуть замінені контакти? Для нас не принципово, чи є договір у письмовій формі чи ні. Головне - отримати підтвердження від власника об`єкту.'
        },
        {
          title:
            'Я знаю про актуальний об`єкт, з яким вже співпрацюю, але він відсутній на вашому сайті. Чому так?',
          content:
            'Ми вкладаємо багато зусиль у збір якомога більше інформації для вас. Але іноді ми не отримуємо дозволу на розміщення цієї інформації на нашому сайті. Проте не засмучуйтесь, бо "Наш недолік - це ваша перевага". Також рекомендуємо порівнювати інформацію за адресою, а не за номером телефону, оскільки об`єкт може бути доданий самим власником, а не нашим колегою.'
        }
      ]
    };
  }
};
</script>
