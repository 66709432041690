<template>
  <div
    class="password-input"
    :class="{
      _disabled: disabled,
      _lg: isLg,
      _landing: isLanding
    }"
  >
    <div class="password-input__row">
      <input
        :type="showPassword ? 'text' : 'password'"
        v-model="localValue"
        :placeholder="placeholder"
        aria-label="Введіть пароль"
        :disabled="disabled"
        :readonly="readOnly"
        class="password-input__input"
      />

      <button
        type="button"
        class="password-input__toggle"
        aria-label="Перемикання видимості пароля"
        @click.prevent="togglePasswordVisibility"
      >
        <img
          v-if="showPassword"
          src="@/assets/images/icons-svg/eye-password-hide.svg"
          alt=""
          width="24"
          height="24"
        />

        <img
          v-else
          src="@/assets/images/icons-svg/eye-password-show.svg"
          alt=""
          width="24"
          height="24"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Пароль'
    }
  },
  data() {
    return {
      showPassword: false
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    isLg() {
      return this.size && this.size === 'lg';
    },
    isLanding() {
      return this.mode && this.mode === 'landing';
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>

<style lang="sass">
.password-input
  --input-height: 1.5rem

  position: relative
  border: none
  border-radius: 1rem
  background-color: var(--color-blue-whale)
  font-size: 0.8125rem
  font-weight: 200
  line-height: 1
  color: var(--color-white)

  &._disabled
    opacity: 0.8

  &._lg
    --input-height: 2.5rem

    width: 100%
    background-color: var(--input-bg-color)
    font-size: var(--input-font-size)
    font-weight: var(--input-font-weight)
    line-height: var(--input-line-height)
    color: var(--input-text-color)
    transition: border-color var(--transition-duration-primary)

  &._landing
    --input-height: 2.25rem

    border: 1px solid var(--color-easy-mint)
    border-radius: var(--input-height)
    font-size: 1rem
    background-color: var(--color-easy-white)
    color: var(--color-easy-blue)
    overflow: hidden

    &:placeholder
      color: var(--color-easy-gray)

  &__row
    display: flex
    align-items: center
    min-height: var(--input-height)
    padding: 0.125rem 0.5rem 0.125rem 2.375rem

    @include media-breakpoint-down(md)
      padding-left: 1.75rem


  &__input
    flex: 1 1 auto
    border: none
    background: none
    font-size: inherit
    font-weight: inherit
    line-height: inherit
    color: inherit
    padding: 0.25rem
    &:focus
      outline: none

  &__toggle
    --btn-size: 2rem

    position: relative
    z-index: 9
    display: flex
    align-items: center
    justify-content: center
    border: none
    width: var(--btn-size)
    height: var(--btn-size)
    padding: 0.375rem
    background: none

    &:not(:disabled)
      cursor: pointer

    img
      max-width: 100%
      max-height: 100%
      object-fit: contain
</style>
