<template>
  <b-modal
    :class="[$bem(), 'modal-add']"
    id="referral-popup"
    :static="true"
    aria-labelledby="referral-popup-title"
    hide-footer
  >
    <div slot="modal-header" :class="$bem('header')">
      <h5 id="referral-popup-title" class="modal-add-title">Запросити друга</h5>

      <button
        class="modal-add__close-btn"
        aria-label="Close Referral Modal"
        @click="$bvModal.hide('referral-popup')"
      >
        <img
          src="@/assets/icons/modal-close.svg"
          alt=""
          width="28"
          height="28"
          aria-hidden="true"
        />
      </button>
    </div>

    <div :class="[$bem('container')]">
      <div>
        <p>
          <b :class="$bem('title')">
            Запроси колегу - отримайте по 50 EasyCoin на рахунок
          </b>
          <br />
        </p>

        <p>
          Коли твій колега, пройде реєстрацію і оплатить досту до бази, ви
          обидва отримаєте по 50 EasyCoin на свій рахунок.
        </p>
      </div>

      <div :class="$bem('phone-block')">
        <label :class="$bem('phone-label')">Введіть номер</label>

        <div class="d-flex">
          <div>
            <PhoneInput v-model="phone" />
          </div>

          <button
            :class="[{ disabled: phone.length < 10 }, 'btn-filter ml-1']"
            :disabled="phone.length < 10"
            @click="sendPhone(phone)"
          >
            Запросити
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import api from '@/api';
import component from '@/mixins/component';
import PhoneInput from '@/components/forms/PhoneInput.vue';

export default {
  name: 'popup-referral',
  mixins: [component],
  components: {
    PhoneInput
  },
  data: () => ({
    phone: ''
  }),
  methods: {
    async sendPhone(phone) {
      const data = await api.sendPhoneReferral(`${phone}`);

      if (data.data.is_sent)
        this.$notify({
          group: 'app',
          duration: 6000,
          type: 'success',
          text: 'СМС на номер вашого друга успішно надіслано!'
        });

      this.$bvModal.hide('referral-popup');

      if (data.status !== 200) {
        const errors = Object.values(data.data);
        errors.forEach((message) => {
          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'error',
            text: message
          });
        });
      }
    }
  }
};
</script>
