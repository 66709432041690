import { QUOTES } from '@/constants/rocket-quotes';

const DISPLAY_DURATION = 7000;

export const state = () => ({
  showRocket: false,
  showQuote: false,
  currentQuote: '',
  quotes: QUOTES,
  showReadMessage: false
});

export const getters = {
  getShowRocket: (state) => state.showRocket,
  getShowQuote: (state) => state.showQuote,
  getCurrentQuote: (state) => state.currentQuote
};

export const mutations = {
  LAUNCH_ROCKET(state) {
    state.showRocket = true;
  },
  HIDE_ROCKET(state) {
    state.showRocket = false;
  },
  SHOW_QUOTE(state) {
    state.showQuote = true;
  },
  HIDE_QUOTE(state) {
    state.showQuote = false;
  },
  SET_RANDOM_QUOTE(state) {
    if (!state.quotes || state.quotes.length === 0) {
      console.error('❌ ПОМИЛКА: Список цитат порожній!');
      return;
    }

    const seenQuotes = JSON.parse(localStorage.getItem('seenQuotes')) || [];
    let availableQuotes = state.quotes.filter((q) => !seenQuotes.includes(q));

    if (availableQuotes.length === 0) {
      availableQuotes = [...state.quotes];
      localStorage.setItem('seenQuotes', JSON.stringify([]));
    }

    const randomIndex = Math.floor(Math.random() * availableQuotes.length);
    state.currentQuote = availableQuotes[randomIndex];

    seenQuotes.push(state.currentQuote);
    localStorage.setItem('seenQuotes', JSON.stringify(seenQuotes));
  },
  CHECK_READ_MESSAGE(state) {
    try {
      const lastShownDate = localStorage.getItem('lastReadMessageDate');
      const now = new Date();
      const currentDate = now.toISOString().split('T')[0];

      if (lastShownDate !== currentDate && now.getHours() >= 7) {
        state.showReadMessage = true;
        setTimeout(() => {
          state.showReadMessage = false;
        }, DISPLAY_DURATION);

        localStorage.setItem('lastReadMessageDate', currentDate);
      }
    } catch (error) {
      console.error(
        '❌ ПОМИЛКА: Неможливо отримати доступ до localStorage',
        error
      );
    }
  }
};

export const actions = {
  triggerRocket({ state, commit }) {
    if (state.showQuote) return;

    commit('LAUNCH_ROCKET');
    commit('SET_RANDOM_QUOTE');
    commit('SHOW_QUOTE');

    setTimeout(() => {
      commit('HIDE_QUOTE');
      commit('HIDE_ROCKET');
    }, DISPLAY_DURATION);
  },
  closeQuote({ commit }) {
    commit('HIDE_QUOTE');
  },
  checkReadMessage({ commit }) {
    commit('CHECK_READ_MESSAGE');
  }
};
