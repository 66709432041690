<template>
  <div v-if="showSubscriptionInfoBlock" class="subscription-info-block">
    <div class="subscription-info-block__header">
      <h3 class="subscription-info-block__title">Ой-ой, доступ закінчився!</h3>
    </div>

    <div class="subscription-info-block__body">
      <div class="subscription-info-block__img">
        <img
          src="@/assets/images/modal-subscription.webp"
          alt=""
          width="208"
          height="208"
        />
      </div>

      <div class="subscription-info-block__description">
        <p>Поповніть його, щоб повернутися до пошуку!</p>
      </div>
    </div>

    <div v-if="paymentForm" class="subscription-info-block__footer">
      <div v-html="paymentForm" class="cabinet-pay-btn"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionInfoBlock',
  computed: {
    showSubscriptionInfoBlock: {
      set(val) {
        this.$store.commit('SUBSCRIBES', val);
      },
      get() {
        return this.$store.state.profile.subscribe;
      }
    },
    paymentForm() {
      const paymentForms = this.$store.state.profile.userMe.payment_forms;

      if (paymentForms && paymentForms.realty_access) {
        return paymentForms.realty_access;
      }

      return null;
    }
  }
};
</script>

<style lang="sass" scoped>
.subscription-info-block
  text-align: center

  &__header
    &:not(:last-child)
      margin-bottom: 0.5rem

  &__title
    margin-bottom: 0
    font-size: 1.5rem

  &__body
    &:not(:last-child)
      margin-bottom: 1.5rem

  &__img
    margin: 0 auto

    @include media-breakpoint-down(lg)
      max-width: 10rem

    &:not(:last-child)
      margin-bottom: 1.5rem

    img
      display: block
      margin: 0 auto
      max-width: 100%
      height: auto
      object-fit: contain

  &__description
    font-size: 1rem

    p
      margin-bottom: 0

.cabinet-pay-btn
  width: 100%
</style>
