<template>
  <div :class="$bem()">
    <div class="landing-container">
      <h2 id="functional" data-aos="fade-up">Функції та можливості</h2>

      <div :class="$bem('inner')">
        <div :class="[$bem('count'), 'dt-invisible']">
          <span>{{ currentSlide }}</span> / {{ slides.length }}
        </div>

        <div :class="$bem('controls')">
          <img
            src="@/assets/landing/prev.svg"
            alt="prev"
            :class="$bem('prev')"
            @click="prev"
          />

          <img
            src="@/assets/landing/next.svg"
            alt="next"
            :class="$bem('next')"
            @click="next"
          />
        </div>

        <vue-slick-carousel
          v-bind="settings"
          ref="slick"
          @afterChange="updateCurrentSlide"
        >
          <div v-for="(slide, index) in slides" :key="index">
            <div :class="$bem('slide')">
              <div
                :class="$bem('slide-image')"
                :style="{
                  backgroundImage: `url(${require(`@/assets/landing/features/${
                    index + 1
                  }.webp`)})`
                }"
              />

              <div>
                <h3>{{ slide.title }}</h3>

                <p>{{ slide.text }}</p>
              </div>
            </div>
          </div>
        </vue-slick-carousel>

        <div :class="[$bem('count'), 'mb-invisible']">
          <span>{{ currentSlide }}</span> / {{ slides.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  name: 'landing-features',
  mixins: [component],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      currentSlide: 1,
      slides: [
        {
          title: 'Відправляйте',
          text:
            'При натисканні на кнопку буде скопійовано посилання на об`єкт, який ви можете надсилати своїм клієнтам, контактні дані будуть замінені на Ваші'
        },
        {
          title: 'Мобільна версія',
          text:
            'Сайт адаптований для мобільних пристроїв, що дозволить вам мати доступ до всієї актуальній інформації з любої точки світу.'
        },
        {
          title: 'Перенос у архів',
          text:
            'Якщо знаєте, що об`єкт не актуальний, натисніть на червону кнопку і ми приберемо її в архів. За надану інформацію Ви отримуєте бонуси.'
        },
        {
          title: 'Розумний фільтр',
          text:
            'Економте час: при кожному вході база завантажується з останнім налаштуванням фільтру. '
        },
        {
          title: 'Клієнти',
          text:
            'Ми знаємо, якою ціною дістаються клієнти, і щоб не втрачати їх у записниках, для вашої зручності, ви можете додавати їх у свою базу клієнтів і отримувати автоматичні підбірки за їх запитами.'
        },
        {
          title: 'Особистий сайт',
          text:
            'EasyHata - унікальний інструмент. Надаєте доступ до бази своїм клієнтам і вони самі обирають собі нерухомість. Усі контактні дані автоматично будуть замінені на ваші.'
        },
        {
          title: 'Бот',
          text:
            'Ми повідомимо про надходження нових об`єктів для ваших клієнтів, як тільки об`єкт з`явиться на сайті.'
        },
        {
          title: 'Редагуй та оновлюй об’єкти',
          text:
            'Для оновлення інформації не потрібно створювати новий об`єкт, достатньо просто оновити інформацію, що оновить дату актуальності.'
        },
        {
          title: 'Захист фото',
          text:
            'Відтепер ваші фото не можуть бути використані без вашої згоди. Ми попіклувалися про те, щоб ваші фото були захищені від використання в рекламних цілях без вашої згоди.'
        },
        { title: 'Мітки', text: 'Зручне інформування без відкриття об`єкту.' },
        {
          title: 'Стікери',
          text:
            'У нас є власні стікери, які дуже зручно використовувати в переписках з клієнтами та колегами.'
        }
      ],
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    };
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    updateCurrentSlide(slide) {
      this.currentSlide = slide + 1;
    }
  }
};
</script>
