import Vue from 'vue';
import api from '@/api';
import { requestParamsGenerateUrl } from '../../utils/url-builder';
import setFilter from '@/utils/set-initial-filter';
import router from '@/router';

const prepareParamsForSending = (data) => {
  const prepareData = {};
  (prepareData.client_name = data.name),
    (prepareData.client_phone = data.phone),
    (prepareData.city = data.city?.id);
  prepareData.district = data.district.map((item) => item.id);
  prepareData.building_id = data.building?.id ?? null;
  prepareData.microdistrict = data.microdistrict.map((item) => item.id);
  prepareData.residential_complex = data.residentialComplex?.map(
    (item) => item.id
  );
  prepareData.street = data.street.map((item) => item.id);
  prepareData.repair = data.repair.map((item) => item.value);
  prepareData.apt_type = data.aptType?.map((item) => item.value);
  prepareData.type = data.type;
  prepareData.floor_from = data.floorFrom;
  prepareData.floor_to = data.floorTo;
  prepareData.floors_from = data.floorsFrom;
  prepareData.floors_to = data.floorsTo;
  prepareData.square_area_from = data.squareAreaFrom;
  prepareData.square_area_to = data.squareAreaTo;
  prepareData.house_number = data.houseNumber;
  prepareData.business_type = data.businessType;
  prepareData.price_from = data.priceFrom;
  prepareData.price_to = data.priceTo;
  prepareData.rooms = data.rooms;
  prepareData.comment = data.comment;
  prepareData.square_common_from = data.squareCommonFrom;
  prepareData.square_common_to = data.squareCommonTo;

  prepareData.is_cashless_payment_available =
    data.is_cashless_payment_available;
  prepareData.is_children_allowed = data.is_children_allowed;
  prepareData.is_foreigners_allowed = data.is_foreigners_allowed;
  prepareData.is_pets_allowed = data.is_pets_allowed;
  prepareData.is_seller_pays_the_commission =
    data.is_seller_pays_the_commission;
  prepareData.is_used_for_advertising = data.is_used_for_advertising;

  if (!data.type === 'house') {
    delete prepareData.square_area;
  }

  if (data.type === 'land') {
    prepareData.purpose = data.purpose;

    prepareData.square_area_from = data.squareAreaFrom;
    prepareData.square_area_to = data.squareAreaTo;

    prepareData.length_from = data.length_from;
    prepareData.length_to = data.length_to;

    prepareData.width_from = data.width_from;
    prepareData.width_to = data.width_to;

    prepareData.communications = data.communications;
  }

  return prepareData;
};

export const state = {
  objects: [],
  page: 1,
  count: 0,
  loaderObject: false,
  clientFilters: {}
};

export const getters = {
  initialGetParamsFilter(state, getters, rootState) {
    if (router.currentRoute.name === 'search') {
      return `?page=${state.page}`;
    }

    let url = `?page=${state.page}&type=${rootState.filter_header.buildingType}&business_type=${rootState.filter_header.businessType}`;

    if (router.currentRoute.name === 'archive') {
      url += '&is_archive=true';
    }

    return url;
  },
  getParamsFilter: (state, getters, rootState) => () => {
    if (router.currentRoute.name === 'search') {
      return state.clientFilters;
    }

    return {
      ...rootState.filter_address.form,
      ...rootState.filter_communication.form,
      ...rootState.filter_condition.form,
      ...rootState.filter_date.form,
      ...rootState.filter_label.form,
      ...rootState.filter_options.form,
      ...rootState.filter_number_of_storeys.form,
      ...rootState.filter_phone.form,
      ...rootState.filter_price.form,
      ...rootState.filter_source.form,
      ...rootState.filter_square.form,
      ...rootState.filter_type.form,
      ...rootState.filter_option.form,
      ...rootState.filter_lands_purpose.form,
      ...rootState.filter_lands_width.form,
      ...rootState.filter_lands_length.form,
      ...rootState.filter_lands_cadastral_number.form,
      ...rootState.filter_lands_communications.form,
      ...rootState.filter_parking_type.form,
      ...rootState.filter_parking_communications.form,
      ...rootState.filter_parking_complectations.form
    };
  }
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_COUNT(state, payload) {
    state.count = payload;
  },
  SET_OBJECTS(state, payload) {
    state.objects = payload;
  },
  LOADER_OBJECT(state, payload) {
    state.loaderObject = payload;
  },
  SET_CLIENT_FILTERS(state, payload) {
    state.clientFilters = payload;
  },
  NEW_COMMENT(state, comment) {
    state.objects.find((i) => i.id === comment.id).comments.push(comment);
  }
};

export const actions = {
  async getObjects({ state, getters, commit, dispatch, rootState }, payload) {
    let response = null;
    const isSearchById =
      payload && payload.isSearchById ? payload.isSearchById : false;
    const getLastFilter =
      payload && payload.getLastFilter ? payload.getLastFilter : false;

    commit('LOADER_OBJECT', true);
    try {
      if (getLastFilter) {
        response = await api.getLastSavedFilter();

        if (response.status === 200) {
          await setFilter(response.data.query, rootState, commit);
        }
      }

      if (isSearchById) {
        response = await api.getRealtyIById(
          `?id=${rootState.filter_header.searchId}${
            router.currentRoute.name === 'archive' ? '&is_archive=true' : ''
          }`
        );
      } else {
        const params = payload?.isClient
          ? prepareParamsForSending(getters.getParamsFilter())
          : getters.getParamsFilter();

        response = await api.getObjects(
          getters.initialGetParamsFilter + requestParamsGenerateUrl(params),
          payload?.type || rootState.filter_header.buildingType
        );
      }

      if (response.status === 200) {
        if (state.page === 1) {
          commit('SET_OBJECTS', response.data.results);
        } else {
          commit('SET_OBJECTS', [...state.objects, ...response.data.results]);
        }

        commit('SET_PAGE', response.data.next);
        commit('SET_COUNT', response.data.count);
      } else if (response.status === 429) {
        await Vue.prototype.$recaptchaLoaded();
        const token = await Vue.prototype.$recaptcha('login');
        const { status } = await api.getRecaptcha(token);

        if (status !== 200) {
          commit('logout');
          localStorage.removeItem('accessToken');
          router.push('/login');
        } else {
          await dispatch('getObjects', payload);
        }
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status !== 403
      ) {
        Vue.prototype.$notify({
          group: 'app',
          type: 'error',
          text: "Об'єкт не знайдено",
          ignoreDuplicates: true
        });
      }
    } finally {
      commit('LOADER_OBJECT', false);
    }
  },
  resetFilters({ commit }) {
    commit('RESET_FILTER_ADDRESS');
    commit('RESET_FILTER_COMMUNICATION');
    commit('RESET_FILTER_CONDITION');
    commit('RESET_FILTER_DATES');
    commit('RESET_FILTER_LABELS');
    commit('RESET_FILTER_OPTIONS');
    commit('RESET_FILTER_NUMBER_OF_STOREYS');
    commit('RESET_FILTER_PHONE');
    commit('RESET_FILTER_PRICE');
    commit('RESET_FILTER_SOURCE');
    commit('RESET_FILTER_SQUARE');
    commit('RESET_FILTER_TYPE');
    commit('RESET_FILTER_OPTION');
    commit('RESET_FILTER_LANDS_PURPOSE');
    commit('RESET_FILTER_LANDS_COMMUNICATIONS');
    commit('RESET_FILTER_CADASTRAL_NUMBER');
    commit('RESET_FILTER_LANDS_WIDTH');
    commit('RESET_FILTER_LANDS_LENGTH');
    commit('RESET_FILTER_PARKING_COMMUNICATIONS');
    commit('RESET_FILTER_PARKING_COMPLECTATIONS');
    commit('RESET_FILTER_PARKING_TYPE');
  }
};
