<template>
  <div :class="$bem()">
    <div :class="$bem('inner')">
      <div :class="$bem('container')">
        <div :class="$bem('wrap')">
          <e-text is="h3" :class="$bem('title')">
            {{ isEdit ? 'Редагувати' : 'Додати' }} об`єкт
          </e-text>

          <div ref="formContainer" :class="$bem('form-container')">
            <!-- Block Загрузка фото -->
            <context-wrapper class="mb-4" title="Загрузка фото">
              <div class="pt-3">
                <image-uploader
                  v-model="newImages"
                  :images="form.images"
                  :apartment-id="form.id"
                  :limit="imagesLimit"
                  @update-sorting="newSorting = $event"
                />
              </div>
            </context-wrapper>

            <!-- Block Параметри 1 -->
            <context-wrapper class="mb-4" title="Параметри 1">
              <div class="row mt-4">
                <!-- Тип нерухомості -->
                <div class="col-12 col-md-6 col-lg-4">
                  <type-real-estate
                    v-model="form.type"
                    v-bind="{
                      label: 'Тип',
                      required: true,
                      error: errors.type
                    }"
                    :disabled="isEdit"
                  />
                </div>

                <!-- Продам/Здам -->
                <div class="col-12 col-md-6 col-lg-auto">
                  <business-type
                    v-model="form.business_type"
                    :disabled="isEdit"
                    :required="true"
                    :error="errors.business_type"
                  />
                </div>

                <div v-if="isRent" class="attention col-12 col-lg-4">
                  <span>
                    Будь ласка, зверніть увагу: об'єкти оренди автоматично
                    переходять в архів, якщо їх актуальність не оновлюється
                    протягом 10 днів. Ви можете легко підтримувати актуальність
                    своїх об'єктів за допомогою нашого Telegram-бота.
                  </span>
                </div>
              </div>
            </context-wrapper>

            <!-- Block Адреса -->
            <context-wrapper class="mb-4" title="Адреса">
              <div class="row">
                <!-- Локація -->
                <div
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <location-select
                    v-model="modelCity"
                    v-bind="{
                      title: 'Локація',
                      required: true,
                      error: errors.city
                    }"
                    :class="{ _error: errors.city }"
                  />
                </div>

                <!-- Район -->
                <div
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <districts-select
                    v-model="form.district"
                    v-bind="{
                      title: 'Район',
                      required: true,
                      error: errors.district
                    }"
                    :class="{ _error: errors.district }"
                  />
                </div>

                <!-- Мікрорайон -->
                <div
                  v-if="form.city && form.city.is_city"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <microdistricts-select
                    v-model="form.microdistrict"
                    v-bind="{
                      title: 'Мікрорайон',
                      required: true,
                      error: errors.microdistrict
                    }"
                    :class="{ _error: errors.microdistrict }"
                  />
                </div>

                <!-- Вулиця -->
                <div
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <streets-select
                    v-model="form.street"
                    v-bind="{
                      title: 'Вулиця',
                      required: true,
                      error: errors.street
                    }"
                    :class="{ _error: errors.street }"
                  />
                </div>

                <!-- Номер будинку -->
                <div
                  v-if="!isLand"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4',
                    'col-12',
                    'col-sm-6',
                    'col-md-2'
                  ]"
                >
                  <numeric-type-field
                    v-model="form.house_number"
                    v-bind="{
                      label: 'Номер будинку',
                      error: errors.house_number
                    }"
                    :class="{ _error: errors.house_number }"
                  />
                </div>

                <!-- Літера -->
                <div
                  v-if="!isLand"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4',
                    'col-12',
                    'col-sm-6',
                    'col-md-2'
                  ]"
                >
                  <house-letters v-model="form.house_letter" />
                </div>

                <!-- Корпус -->
                <div
                  v-if="isFlat"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <e-input v-model="form.house_hull" label="Корпус" />
                </div>

                <!-- ЖК -->
                <div
                  v-if="!isLand"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <residential-complex-select
                    v-if="isCommerce"
                    v-model="form.building"
                    :is-building="true"
                    title="ЖК, ТЦ, ТРЦ, БЦ"
                  />

                  <residential-complex-select
                    v-else-if="isParking"
                    v-model="form.residential_complex"
                    :is-building="false"
                    title="ЖК"
                  />

                  <residential-complex-select
                    v-else
                    v-model="form.residential_complex"
                    title="ЖК"
                    :is-building="false"
                    :error="errors.residential_complex"
                    :required="form.apt_type === 'new_building'"
                  />
                </div>

                <!-- Гаражнi кооперативи -->
                <div
                  v-if="isParking"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <residential-complex-select
                    v-model="form.cooperative"
                    :title="'Гаражнi кооперативи'"
                    :is-garage-cooperative="true"
                    :required="form.parking_type === 'cooperative'"
                    :error="errors.cooperative"
                  />
                </div>
              </div>
            </context-wrapper>

            <!-- Block Параметри 2 -->
            <context-wrapper v-if="form.type" class="mb-4" title="Параметри 2">
              <div class="row">
                <!-- Тип об\'єкта -->
                <div
                  v-if="isCommerce"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <type-apartments
                    v-model="form.apt_type"
                    v-bind="{
                      label: 'Тип об\'єкта',
                      type: form.type,
                      required: true,
                      error: errors.apt_type
                    }"
                    :class="{ _error: errors.apt_type }"
                  />
                </div>

                <!-- Тип ділянки -->
                <div
                  v-if="isLand"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <type-apartments
                    v-model="form.purpose"
                    v-bind="{
                      label: 'Тип ділянки',
                      type: form.type,
                      required: true,
                      error: errors.apt_type
                    }"
                  />
                </div>

                <!-- Тип паркінгу -->
                <div
                  v-if="isParking"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <type-apartments
                    v-model="form.parking_type"
                    v-bind="{
                      label: 'Тип паркінгу',
                      type: form.type,
                      required: true,
                      error: errors.parking_type
                    }"
                    :class="{ _error: errors.parking_type }"
                  />
                </div>

                <!-- Тип будинку/квартири -->
                <div
                  v-if="!isCommerce && !isLand && !isParking"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <type-apartments
                    v-model="form.apt_type"
                    v-bind="{
                      label: isHouse ? 'Тип будинку' : 'Тип квартири',
                      type: form.type,
                      required: true,
                      error: errors.apt_type,
                      roomsError: errors.rooms
                    }"
                    :rooms="form.rooms"
                    :class="{ _error: errors.apt_type || errors.rooms }"
                    @roomsChange="(val) => $set(this.form, 'rooms', val)"
                  />
                </div>

                <!-- Тип матеріалу -->
                <div
                  v-if="isTypeMaterial && !isLand && !isParking"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <type-material
                    v-model="form.material_type"
                    :type="form.type"
                  />
                </div>

                <!-- Тип матеріалу (Гаражі) -->
                <div
                  v-if="isTypeMaterial && isParking"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <type-material v-model="form.material" :type="form.type" />
                </div>

                <!-- Стан -->
                <div
                  v-if="!isLand && !isParking"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <select-config
                    v-model="form.repair"
                    v-bind="{
                      title: 'Стан',
                      required: true,
                      error: errors.repair,
                      multiple: false
                    }"
                    :class="{ _error: errors.repair }"
                  />
                </div>

                <!-- Кутова -->
                <div
                  v-show="isFlat"
                  class="col-12 col-md-6 col-lg-4 mt-4"
                  :class="[calculateClassForBigComponent]"
                >
                  <div class="pl-1">
                    <e-checkbox v-model="form.is_angled" label="Кутова" />
                  </div>
                </div>
              </div>
            </context-wrapper>

            <!-- Block Параметри 3 -->
            <context-wrapper v-if="form.type" class="mb-4" title="Параметри 3">
              <div class="row">
                <!-- Кадастровий номер -->
                <div
                  v-if="isLand"
                  class="mt-4 col-12 col-md-4"
                  :class="[calculateClassForSmallComponent]"
                >
                  <e-input
                    v-model="form.cadastral_number"
                    v-bind="{
                      label: 'Кадастровий номер',
                      error: errors.cadastral_number,
                      required: true
                    }"
                    v-mask="'##########:##:###:####'"
                    placeholder="XXXXXXXXXX:XX:XXX:XXXX"
                  />
                </div>

                <!-- Загальна -->
                <div
                  v-if="isSquareCommon && !isLand"
                  class="col-12 col-sm-6 col-lg-4 mt-4"
                >
                  <numeric-type-field
                    v-model="form.square_common"
                    v-bind="{
                      label: squareCommonLabel,
                      required: true,
                      error: errors.square_common
                    }"
                    :class="{ _error: errors.square_common }"
                  />
                </div>

                <!-- Житлова -->
                <div
                  v-if="isFlat"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4',
                    'col-12',
                    'col-sm-6',
                    'col-md-4'
                  ]"
                >
                  <numeric-type-field
                    v-model="form.square_living"
                    v-bind="{
                      label: 'Житлова',
                      error: errors.square_living
                    }"
                    :class="{ _error: errors.square_living }"
                  />
                </div>

                <!-- К-сть соток -->
                <div
                  v-if="isSquareArea && !isLand"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4 col-12 col-sm-6 col-md-4'
                  ]"
                >
                  <numeric-type-field
                    v-model="form.square_area"
                    v-bind="{
                      label: 'К-сть соток',
                      required: !isCommerce || form.apt_type === 'farm',
                      error: errors.square_area
                    }"
                    :class="{ _error: errors.square_area }"
                  />
                </div>

                <!-- К-сть соток (Земельні ділянки) -->
                <div
                  v-if="isLand"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4',
                    'col-12',
                    'col-md-4'
                  ]"
                >
                  <numeric-type-field
                    v-model="form.square_area"
                    v-bind="{
                      label: 'К-сть соток',
                      required: true,
                      error: errors.square_area
                    }"
                  />
                </div>

                <!-- Placeholder for grid -->
                <div
                  v-if="isLand"
                  class="col-12 col-md-4"
                  aria-hidden="true"
                ></div>

                <!-- Довжина (ділянки) -->
                <div
                  v-if="isLand"
                  class="mt-4 col-12 col-md-4"
                  :class="[calculateClassForSmallComponent]"
                >
                  <numeric-type-field
                    v-model="form.length"
                    v-bind="{
                      label: 'Довжина, M'
                    }"
                  />
                </div>

                <!-- Ширина (ділянки) -->
                <div
                  v-if="isLand"
                  class="mt-4 col-12 col-md-4"
                  :class="[calculateClassForSmallComponent]"
                >
                  <numeric-type-field
                    v-model="form.width"
                    v-bind="{
                      label: 'Ширина, M'
                    }"
                  />
                </div>

                <!-- Placeholder for grid -->
                <div
                  v-if="isLand"
                  class="col-12 col-md-4"
                  aria-hidden="true"
                ></div>

                <!-- К-сть кабінетів/кімнат -->
                <div
                  v-if="isRooms && !isLand && !isParking"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4 col-12 col-sm-6 col-md-4'
                  ]"
                >
                  <numeric-type-field
                    v-model="form.rooms"
                    v-bind="{
                      label:
                        form.apt_type === 'office_room'
                          ? 'К-сть кабінетів'
                          : 'К-сть кімнат',
                      error: errors.rooms
                    }"
                    :class="{ _error: errors.rooms }"
                  />
                </div>

                <!-- Кухня -->
                <div
                  v-if="!isCommerce && !isLand && !isParking"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4 col-12 col-sm-6 col-md-4'
                  ]"
                >
                  <numeric-type-field
                    v-model="form.square_kitchen"
                    v-bind="{
                      label: 'Кухня',
                      error: errors.square_kitchen
                    }"
                    :class="{ _error: errors.square_kitchen }"
                  />
                </div>

                <!-- Поверх -->
                <div
                  v-if="isFloor"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4 col-12 col-sm-6 col-md-4'
                  ]"
                >
                  <numeric-type-field
                    v-model="form.floor"
                    v-bind="{
                      label: 'Поверх',
                      required: !isCommerce,
                      error: errors.floor
                    }"
                    :class="{ _error: errors.floor }"
                  />
                </div>

                <!-- Поверховість -->
                <div
                  v-if="isFloors"
                  :class="[
                    calculateClassForSmallComponent,
                    'mt-4 col-12 col-sm-6 col-md-4'
                  ]"
                >
                  <numeric-type-field
                    v-model="form.floors"
                    v-bind="{
                      label: 'Поверховість',
                      required: !isCommerce,
                      error: errors.floors
                    }"
                    :class="{ _error: errors.floors }"
                  />
                </div>

                <!-- Паркомісць -->
                <div v-if="isParking" class="mt-4 col-12 col-sm-6 col-lg-4">
                  <LabelledCount
                    v-model="form.vehicle_places"
                    :label="TEXT_LABELS.vehiclePlacesLabel"
                    :required="true"
                    :error="!!errors.vehicle_places"
                  />
                </div>

                <!-- Вартість -->
                <div class="col-12 col-sm-6 col-lg-4 mt-4">
                  <numeric-type-field
                    v-model="form.price"
                    v-bind="{
                      label: 'Вартість ' + (isSell ? '$' : '₴'),
                      required: true,
                      error: errors.price
                    }"
                    :class="{ _error: errors.price }"
                  />
                </div>

                <!-- Additions -->
                <div
                  v-if="isAdditions"
                  :class="[
                    calculateClassForBigComponent,
                    'mt-4 col-12 col-sm-6 col-md-4'
                  ]"
                >
                  <additions v-model="form.options" :apt_type="form.apt_type" />
                </div>
              </div>
            </context-wrapper>

            <!-- Block Параметри 4 -->
            <context-wrapper
              v-if="form.type && isHouse"
              class="mb-4"
              title="Параметри 4"
            >
              <div class="row">
                <!-- Опалення -->
                <div
                  :class="[
                    calculateClassForMediumComponent,
                    'mt-4 col-12 col-sm-6 col-md-3'
                  ]"
                >
                  <communication-field
                    v-model="form.heating"
                    v-bind="{ title: 'Опалення' }"
                    :arrayOptions="heating_object"
                  />
                </div>

                <!-- Вода -->
                <div
                  :class="[
                    calculateClassForMediumComponent,
                    'mt-4 col-12 col-sm-6 col-md-3'
                  ]"
                >
                  <communication-field
                    v-model="form.water"
                    v-bind="{ title: 'Вода' }"
                    :arrayOptions="water_object"
                  />
                </div>

                <!-- Газ -->
                <div
                  :class="[
                    calculateClassForMediumComponent,
                    'mt-4 col-12 col-sm-6 col-md-3'
                  ]"
                >
                  <communication-field
                    v-model="form.gas"
                    v-bind="{ title: 'Газ' }"
                    :arrayOptions="gas_object"
                  />
                </div>

                <!-- Каналізація -->
                <div
                  :class="[
                    calculateClassForMediumComponent,
                    'mt-4 col-12 col-sm-6 col-md-3'
                  ]"
                >
                  <communication-field
                    v-model="form.sewers"
                    v-bind="{ title: 'Каналізація' }"
                    :arrayOptions="sewers_object"
                  />
                </div>
              </div>
            </context-wrapper>

            <!-- Block Параметри 4: Земельні ділянки -->
            <context-wrapper
              v-if="form.type && isLand"
              class="mb-4"
              title="Комунікації"
            >
              <div class="pt-3">
                <ul class="pl-0 list-unstyled app-columns app-columns--3x">
                  <li
                    v-for="(comm, commIndex) in sortedLandCommunications"
                    :key="commIndex"
                    class="mb-1"
                  >
                    <e-checkbox
                      v-model="form.communications"
                      :label="comm.label"
                      :value="comm.value"
                      :bold-label="true"
                      :disabled="shouldLandCommunicationsDisable(comm.value)"
                      @change="
                        handleLandCommunicationsCheckboxChange(comm.value)
                      "
                    />
                  </li>
                </ul>
              </div>
            </context-wrapper>

            <!-- Block Параметри 4: Комплектація (Гаражі) -->
            <context-wrapper
              v-if="form.type && isParking"
              class="mb-4"
              title="Комплектація"
            >
              <div class="pt-3">
                <ul class="pl-0 list-unstyled app-columns app-columns--3x">
                  <li
                    v-for="(comp, compIndex) in sortedParkingComplectations"
                    :key="compIndex"
                    class="mb-1"
                  >
                    <e-checkbox
                      v-model="form.complectations"
                      :label="comp.label"
                      :value="comp.value"
                      :bold-label="true"
                      :disabled="
                        shouldCheckboxDisable(form.complectations, comp.value)
                      "
                      @change="
                        handleParkingComplectationsCheckboxChange(comp.value)
                      "
                    />
                  </li>
                </ul>
              </div>
            </context-wrapper>

            <!-- Block Параметри 4: Комунікації (Гаражі) -->
            <context-wrapper
              v-if="form.type && isParking"
              class="mb-4"
              title="Комунікації"
            >
              <div class="pt-3">
                <ul class="pl-0 list-unstyled app-columns app-columns--3x">
                  <li
                    v-for="(comm, commIndex) in sortedParkingCommunications"
                    :key="commIndex"
                    class="mb-1"
                  >
                    <e-checkbox
                      v-model="form.communications"
                      :label="comm.label"
                      :value="comm.value"
                      :bold-label="true"
                      :disabled="
                        shouldCheckboxDisable(form.communications, comm.value)
                      "
                      @change="
                        handleParkingCommunicationsCheckboxChange(comm.value)
                      "
                    />
                  </li>
                </ul>
              </div>
            </context-wrapper>

            <!-- Block Параметри 5 -->
            <context-wrapper
              v-if="form.type"
              :title="`Параметри ${isHouse || isLand || isParking ? 5 : 4}`"
            >
              <div class="pt-3">
                <div class="row mb-3">
                  <div
                    v-if="isRent && !isCommerce && !isParking"
                    class="col-12 col-lg-4 mb-3"
                  >
                    <AdditionalOptions
                      v-model="form.is_pets_allowed"
                      :error="errors.is_pets_allowed"
                      title="Можна з тваринами?"
                      required
                    />
                  </div>

                  <div v-if="isRentFlat" class="col-12 col-lg-4 mb-3">
                    <AdditionalOptions
                      v-model="form.is_children_allowed"
                      :error="errors.is_children_allowed"
                      title="Можна з дітьми?"
                      required
                    />
                  </div>

                  <div
                    v-if="isRentFlat || isRentHouse"
                    class="col-12 col-lg-4 mb-3"
                  >
                    <AdditionalOptions
                      v-model="form.is_foreigners_allowed"
                      :error="errors.is_foreigners_allowed"
                      title="Беруть іноземців?"
                      required
                    />
                  </div>

                  <div v-if="isSell" class="col-12 col-lg-4 mb-3">
                    <AdditionalOptions
                      v-model="form.is_cashless_payment_available"
                      :error="errors.is_cashless_payment_available"
                      title="Можливий безготівковий розрахунок?"
                      required
                    />
                  </div>

                  <div class="col-12 col-lg-4 mb-3">
                    <AdditionalOptions
                      v-model="form.is_used_for_advertising"
                      :error="errors.is_used_for_advertising"
                      title="Надати дозвіл колегам використовувати фото на рекламних майданчиках?"
                      required
                    />
                  </div>

                  <div class="col-12 col-lg-4 mb-3">
                    <AdditionalOptions
                      v-model="form.is_seller_pays_the_commission"
                      :error="errors.is_seller_pays_the_commission"
                      title="Продавець оплачує комісію?"
                      required
                    />
                  </div>

                  <div v-if="isShowStatusObject" class="col-12">
                    <status-object v-model="form.status" />
                  </div>
                </div>

                <div class="mb-3">
                  <RichTextEditor v-model="form.text" placeholder="Опис" />
                </div>

                <div class="">
                  <p>
                    Будь ласка, переконайтесь в тому, що ви додаєте об'єкт по
                    якому маєте прямий контакт з власником.
                  </p>
                </div>
              </div>
            </context-wrapper>
          </div>

          <div v-if="form.type" class="mt-2 mt-md-4">
            <div class="text-center">
              <e-btn
                :disabled="disabledButton"
                :class="['btn-large', $bem('btn-submit'), disabledButton]"
                @click="submit"
              >
                <span v-if="disabledButton" class="d-flex align-items-center">
                  <div class="btn-loader mr-2">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>

                  {{ isEdit ? 'Редагую' : 'Завантажую' }} об'єкт
                </span>

                <span v-else>{{ isEdit ? 'Редагувати' : 'Додати' }}</span>
              </e-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach, isEmpty } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import component from '@/mixins/component';
import LabelsMixin from '@/mixins/LabelsMixin';

import ContextWrapper from '@/components/context-wrapper';
import eInput from '@/elements/inputs/e-input';
import eCheckbox from '@/elements/inputs/e-checkbox';
import eTextarea from '@/elements/e-textarea';
import eBtn from '@/elements/buttons/e-btn';
import imageUploader from '@/components/app/image-uploader';
import typeRealEstate from '@/components/estate/type-real-estate';
import BusinessType from '@/components/business-type';
import locationSelect from '@/components/address/location';
import districtsSelect from '@/components/address/districts';
import microdistrictsSelect from '@/components/address/microdistricts';
import streetsSelect from '@/components/address/streets';
import residentialComplexSelect from '@/components/address/residential-complex';
import typeApartments from '@/components/estate/type-apartments';
import typeMaterial from '@/components/estate/type-material';
import labels from '@/components/popups/add-object-popup/v2/components/labels';
import additions from '@/components/popups/add-object-popup/v2/components/additions';
import selectConfig from '@/components/select-config';
import statusObject from '@/components/popups/add-object-popup/v2/components/status-object';
import AdditionalOptions from '@/components/popups/add-object-popup/v2/components/additional-options';
import RichTextEditor from '@/components/app/RichTextEditor.vue';
import eCounter from '@/elements/e-counter';
import LabelledCount from '@/components/estate/labelled-count/labelled-count.vue';
import HouseLetters from '@/components/address/house-letters';
import NumericTypeField from '@/elements/inputs/numeric-input/numeric-input';
import CommunicationField from '@/components/popups/add-object-popup/v2/components/communication-field';

import {
  LAND_COMMUNICATIONS,
  PARKING_COMMUNICATIONS,
  PARKING_COMPLECTATIONS,
  ESTATE_TYPES,
  TEXT_LABELS
} from '@/helpers/constants';

import { objectToArray } from '@/utils/object-to-array';

import {
  apartmentsValidate,
  defaultData,
  getMessage,
  prepareValidationFields
} from '@/components/popups/add-object-popup/v2/helpers';

const initialForm = {
  type: '',
  apt_type: '',
  business_type: null,
  city: null,
  district: [],
  microdistrict: [],
  street: [],
  material_type: '',
  labels: [],
  options: [],
  house_letter: '',
  price: null,
  floors: null,
  floor: null,
  user_label: [],
  house_number: '',
  residential_complex: [],
  building: [],
  square_common: null,
  square_living: null,
  square_kitchen: null,
  text: '',
  images: [],
  house_hull: '',
  repair: '',
  square_area: null,
  source: 'rieltor',
  rooms: 0,
  is_angled: false,
  water: '',
  sewers: '',
  gas: '',
  heating: '',
  purpose: '',
  width: null,
  length: null,
  cadastral_number: '',
  communications: [],

  parking_type: '',
  material: '',
  cooperative: null,
  vehicle_places: 0,
  complectations: [],

  is_pets_allowed: null,
  is_children_allowed: null,
  is_foreigners_allowed: null,
  is_cashless_payment_available: null,
  is_used_for_advertising: null,
  is_seller_pays_the_commission: null,
  status: false
};

export default {
  name: 'manageObjectFormPage',
  mixins: [component, LabelsMixin],
  components: {
    CommunicationField,
    NumericTypeField,
    HouseLetters,
    statusObject,
    eBtn,
    selectConfig,
    eTextarea,
    eCheckbox,
    eInput,
    labels,
    typeMaterial,
    typeApartments,
    residentialComplexSelect,
    streetsSelect,
    microdistrictsSelect,
    districtsSelect,
    locationSelect,
    BusinessType,
    typeRealEstate,
    imageUploader,
    additions,
    ContextWrapper,
    AdditionalOptions,
    RichTextEditor,
    eCounter,
    LabelledCount
  },
  data() {
    return {
      form: {
        ...initialForm
      },
      newImages: [],
      newSorting: [],
      activeObject: {},
      errors: {},
      statusObject: null,
      statusAdvertising: null,
      statusPaysCommission: null,
      cashlessPayments: null,
      statusAnimals: null,
      statusChildren: null,
      statusForeigners: null,
      disabledButton: false,
      imagesLimit: 36,
      formContainerEl: null,
      TEXT_LABELS
    };
  },
  computed: {
    ...mapGetters(['editObject', 'garageCooperatives']),
    isEdit() {
      return !isEmpty(this.editObject);
    },
    modelCity: {
      get() {
        return this.form.city;
      },
      set(val) {
        this.$set(this.form, 'city', val);

        if (val) {
          this.handleGetDistrictByCities(val.id);
        }
      }
    },
    calculateClassForBigComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-4' : 'col-6',
    calculateClassForSmallComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-2' : 'col-4',
    calculateClassForMediumComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-3' : 'col-4',
    isShowStatusObject: ({ form }) => form.status !== 'approved',
    squareCommonLabel() {
      switch (this.form.type) {
        case ESTATE_TYPES.flat:
          return 'Загальна';
        case ESTATE_TYPES.house:
          return 'Пл. будинку М²';
        default:
          return 'Пл. М²';
      }
    },
    isRent() {
      return this.form.business_type === 'rent';
    },
    isSell() {
      return this.form.business_type === 'sell';
    },
    isRentFlat() {
      return (
        this.form.type === ESTATE_TYPES.flat &&
        this.form.business_type === 'rent'
      );
    },
    isRentHouse() {
      return (
        this.form.type === ESTATE_TYPES.house &&
        this.form.business_type === 'rent'
      );
    },
    isFlat() {
      return this.form.type === ESTATE_TYPES.flat;
    },
    isHouse() {
      return this.form.type === ESTATE_TYPES.house;
    },
    isCommerce() {
      return this.form.type === ESTATE_TYPES.commerce;
    },
    isLand() {
      return this.form.type === ESTATE_TYPES.land;
    },
    isParking() {
      return this.form.type === ESTATE_TYPES.parking;
    },
    isFloor() {
      const apt_types = [
        'restaurant_or_cafe_or_bar',
        'office_room',
        'shop',
        'beauty_object',
        'health_object',
        'sport_object',
        'event_object'
      ];

      return (
        this.isFlat ||
        (this.isCommerce && apt_types.includes(this.form.apt_type))
      );
    },
    isFloors() {
      const apt_types = [
        'recreation_center_or_hotel',
        'industrial_premise',
        'free_appointment_premise',
        'MAF',
        'autoservice',
        'trading_area',
        'farm',
        'trading_area',
        'production_object',
        'production_object'
      ];

      return (
        this.isFlat ||
        this.isHouse ||
        (this.isCommerce && !apt_types.includes(this.form.apt_type))
      );
    },
    isRooms() {
      const apt_types = [
        'office_room',
        'detached_building',
        'part_of_the_building',
        'shop',
        'beauty_object',
        'health_object',
        'sport_object'
      ];

      return this.isCommerce && apt_types.includes(this.form.apt_type);
    },
    isSquareArea() {
      const apt_types = [
        'restaurant_or_cafe_or_bar',
        'warehouse_or_hangar',
        'recreation_center_or_hotel',
        'industrial_premise',
        'free_appointment_premise',
        'farm',
        'event_object',
        'production_object'
      ];

      return (
        this.isHouse ||
        (this.isCommerce && apt_types.includes(this.form.apt_type)) ||
        this.isLand
      );
    },
    isAdditions() {
      const apt_types = ['MAF', 'trading_area', 'farm'];

      return this.isCommerce && !apt_types.includes(this.form.apt_type);
    },
    isTypeMaterial: ({ form }) =>
      !['autoservice', 'farm', 'trading_area'].includes(form.apt_type),
    isSquareCommon: ({ form }) => !['farm'].includes(form.apt_type),
    landCommunicationsList() {
      return objectToArray(LAND_COMMUNICATIONS, false);
    },
    sortedLandCommunications() {
      const noneOption = this.landCommunicationsList.find(
        (item) => item.value === 'none'
      );

      const otherOptions = this.landCommunicationsList.filter(
        (item) => item.value !== 'none'
      );

      return [noneOption, ...otherOptions];
    },
    parkingCommunicationsList() {
      return objectToArray(PARKING_COMMUNICATIONS) || [];
    },
    sortedParkingCommunications() {
      const noneOption = this.parkingCommunicationsList.find(
        (item) => item.value === 'none'
      );

      const otherOptions = this.parkingCommunicationsList.filter(
        (item) => item.value !== 'none'
      );

      return [...otherOptions, noneOption];
    },
    parkingComplectationsList() {
      return objectToArray(PARKING_COMPLECTATIONS) || [];
    },
    sortedParkingComplectations() {
      const noneOption = this.parkingComplectationsList.find(
        (item) => item.value === 'none'
      );

      const otherOptions = this.parkingComplectationsList.filter(
        (item) => item.value !== 'none'
      );

      return [...otherOptions, noneOption];
    }
  },
  watch: {
    form: {
      handler(newValue) {
        prepareValidationFields(newValue);

        if (!isEmpty(this.errors)) {
          this.errors = apartmentsValidate(newValue);
        }
      },
      deep: true
    },
    'form.apt_type': {
      handler(newValue, oldValue) {
        if (oldValue && oldValue !== newValue) {
          this.form.options = [];
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== from.name) {
      this.close();
    }

    next();
  },
  mounted() {
    if (!isEmpty(this.editObject)) {
      this.form = JSON.parse(JSON.stringify(this.editObject));

      this.dataPreparationForView();
    }

    // Init Form Container (For Scroll to Errors)
    this.formContainerEl = this.$refs.formContainer;
  },
  methods: {
    ...mapActions([
      'handleGetObjects',
      'handleSortingImagesByApartmentsId',
      'handleCreateApartment',
      'handleEditObject',
      'handleEditApartment',
      'handleExportImages',
      'handleGetDistrictByCities'
    ]),
    async submit() {
      if (this.disabledButton) return;
      this.disabledButton = true;

      const data = this.dataPreparationForSending();

      this.errors = apartmentsValidate(data);

      const validationFields = {
        flat: {
          rent: [
            'is_pets_allowed',
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_children_allowed',
            'is_foreigners_allowed'
          ],
          sell: [
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_cashless_payment_available'
          ]
        },
        house: {
          rent: [
            'is_pets_allowed',
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_foreigners_allowed'
          ],
          sell: [
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_cashless_payment_available'
          ]
        },
        commerce: {
          rent: ['is_used_for_advertising', 'is_seller_pays_the_commission'],
          sell: [
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_cashless_payment_available'
          ]
        },
        land: {
          rent: ['is_used_for_advertising', 'is_seller_pays_the_commission'],
          sell: [
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_cashless_payment_available'
          ]
        },
        parking: {
          rent: ['is_used_for_advertising', 'is_seller_pays_the_commission'],
          sell: [
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_cashless_payment_available'
          ]
        }
      };

      if (data.business_type) {
        const typeObject = validationFields[data.type || 'flat'];

        forEach(typeObject[data.business_type], (key) => {
          if (data[key] === null) {
            this.errors[key] = "Поле обов'язкове для заповнення";
          }
        });
      }

      if (!isEmpty(this.errors)) {
        this.disabledButton = false;

        this.scrollToErrorsFields();
        return;
      }

      try {
        if (this.isEdit) {
          await this.editApartments(data);
        } else {
          await this.createApartments(data);
        }
      } catch (error) {
        this.$notify({
          group: 'app',
          duration: 6000,
          type: 'error',
          text: 'An unexpected error occurred. Please try again.'
        });
      } finally {
        this.disabledButton = false;
      }
    },
    dataPreparationForSending() {
      let data = this.form;

      const prepareData = JSON.parse(JSON.stringify(data));

      if (!isEmpty(data.labels)) {
        prepareData.labels = data.labels.map((item) => item.value);
      }

      prepareData.images = data.images ? data.images : [];
      prepareData.city_id = data.city?.id;
      prepareData.district_id = data.district?.id;
      prepareData.building_id = data.building?.id;
      prepareData.microdistrict = data.microdistrict?.id;
      prepareData.microdistrict_id = data.microdistrict?.id;
      prepareData.street_id = data.street?.id;
      prepareData.status = data && data.status ? 'draft' : 'created';
      // prepareData.is_cashless_payment_available =  this.cashlessPayments;
      // prepareData.is_used_for_advertising = this.statusAdvertising;
      // prepareData.is_seller_pays_the_commission = this.statusPaysCommission;

      if (this.isRentFlat) {
        // prepareData.is_pets_allowed = this.statusAnimals
        // prepareData.is_children_allowed = this.statusChildren
        // prepareData.is_foreigners_allowed = this.statusForeigners
      }

      prepareData.repair = data.repair?.id || data.repair;
      prepareData.gas = data.gas?.id || data.gas;
      prepareData.heating = data.heating?.id || data.heating;
      prepareData.sewers = data.sewers?.id || data.sewers;
      prepareData.water = data.water?.id || data.water;
      // prepareData.currency = data.business_type === 'sell' ? 1 : 2

      prepareData.business_type = data.business_type;
      prepareData.square_common = +data.square_common;
      prepareData.square_kitchen = +data.square_kitchen;
      prepareData.square_living = +data.square_living;

      if (!this.isHouse && data.apt_type !== 'farm' && !this.isLand) {
        delete prepareData.square_area;
      }

      if (this.isCommerce) {
        prepareData.options = data.options;
        prepareData.building_id = data.building?.id;
      } else {
        prepareData.residential_complex_id = data.residential_complex?.id;
        prepareData.rooms = data.rooms;
      }

      if (this.isLand) {
        prepareData.repair = 'without_repair';
        prepareData.purpose = data.purpose;
        prepareData.cadastral_number = data.cadastral_number;
        prepareData.square_area = data.square_area;
        prepareData.width = data.width;
        prepareData.length = data.length;
        prepareData.communications = data.communications;
      }

      if (this.isParking) {
        prepareData.repair = 'without_repair';
        prepareData.cooperative = data.cooperative?.id;
        prepareData.cooperative_id = data.cooperative?.id;
      }

      return prepareData;
    },
    dataPreparationForView() {
      this.statusObject = this.editObject.status === 'draft';
      this.form.status = this.form.status === 'draft';
    },
    async editApartments(data) {
      try {
        const res = await this.handleEditApartment(data);

        if (res.status === 200 || res.status === 204) {
          const message = getMessage(res.data.status);
          this.close();

          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'success',
            text: message
          });

          this.routeToObjectsPage(res.data.type);
        } else {
          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'error',
            text: res.data
          });
        }
      } catch (error) {
        this.$notify({
          group: 'app',
          duration: 6000,
          type: 'error',
          text:
            'An error occurred while editing the apartment. Please try again.'
        });
      } finally {
        this.disabledButton = false;
      }
    },
    async createApartments(data) {
      try {
        const {
          data: responseData,
          status: statusCode
        } = await this.handleCreateApartment(data);

        if (statusCode === 201 || statusCode === 204) {
          const message = getMessage(responseData.status);

          try {
            await this.submitImages(responseData);
            this.close();

            this.routeToObjectsPage(responseData.type);

            this.$notify({
              group: 'app',
              duration: 6000,
              type: 'success',
              text: message
            });
          } catch (error) {
            this.$notify({
              group: 'app',
              duration: 6000,
              type: 'error',
              text: 'Failed to upload images. Please try again later.'
            });
          }
        } else {
          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'error',
            text: responseData
          });
        }
      } catch (error) {
        this.$notify({
          group: 'app',
          duration: 6000,
          type: 'error',
          text:
            'An error occurred while creating the apartment. Please try again.'
        });
      } finally {
        this.disabledButton = false;
      }
    },
    close() {
      this.model = undefined;
      this.clear();
      this.handleEditObject({});
    },
    clear() {
      this.form = JSON.parse(JSON.stringify(defaultData));
    },
    async submitImages(data) {
      if (this.newImages) {
        await this.handleExportImages({ id: data.id, images: this.newImages });
        this.handleGetObjects(data.type);
      }
    },
    shouldLandCommunicationsDisable(value) {
      const noneSelected = this.form.communications.includes('none');
      return noneSelected && value !== 'none';
    },
    handleLandCommunicationsCheckboxChange(value) {
      if (value === 'none' && this.form.communications.includes('none')) {
        this.form.communications = ['none'];
      } else if (value === 'none') {
        this.form.communications = this.form.communications.filter(
          (v) => v !== 'none'
        );
      }
    },
    handleParkingCommunicationsCheckboxChange(value) {
      if (value === 'none' && this.form.communications.includes('none')) {
        this.form.communications = ['none'];
      } else if (value === 'none') {
        this.form.communications = this.form.communications.filter(
          (v) => v !== 'none'
        );
      }
    },
    handleParkingComplectationsCheckboxChange(value) {
      if (value === 'none' && this.form.complectations.includes('none')) {
        this.form.complectations = ['none'];
      } else if (value === 'none') {
        this.form.complectations = this.form.complectations.filter(
          (v) => v !== 'none'
        );
      }
    },
    shouldCheckboxDisable(list, value) {
      const noneSelected = list.includes('none');
      return noneSelected && value !== 'none';
    },
    handleCheckboxChange(list, value) {
      if (value === 'none' && list.includes('none')) {
        list = ['none'];
      } else if (value === 'none') {
        list = list.filter((v) => v !== 'none');
      }
    },
    scrollToErrorsFields() {
      if (!this.formContainerEl) return;

      setTimeout(() => {
        const firstErrorField = this.formContainerEl.querySelector('._error');
        if (firstErrorField) {
          firstErrorField.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }, 100);
    },
    routeToObjectsPage(objectType) {
      this.$router.push({
        name: 'objects',
        params: {
          objectType: objectType || ''
        }
      });
    }
  }
};
</script>

<style lang="sass" src="@/assets/styles/pages/manage-object-form-page.sass" />
