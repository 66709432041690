<template>
  <div class="objects-page">
    <div class="objects-page__row">
      <div class="objects-page__aside">
        <div class="objects-page__filter">
          <div
            :class="{ show: this.$parent.showFiltersModal }"
            ref="resFiltersModal"
          >
            <FilterObjects />
          </div>
        </div>
      </div>

      <div v-show="isLoaded" class="objects-page__main" v-cloak>
        <div v-show="hasResults" class="objects-page__results-wrap">
          <simplebar
            v-show="isDesktop"
            data-simplebar-auto-hide="false"
            class="simplebar-horizontal-only"
            id="catalog-scroll"
          >
            <ul class="objects-page__pc-list catalog-item-container">
              <li v-for="apt in objects.results" :key="apt.id">
                <catalog-card :apartment="apt" :edit="true" :key="apt.id" />
              </li>
            </ul>
          </simplebar>

          <div v-if="isMobile" class="objects-page__mob-wrap">
            <ul class="objects-page__mob-list">
              <li v-for="apt in objects.results">
                <catalog-card :apartment="apt" :key="apt.id" :edit="true" />
              </li>
            </ul>

            <infinite-loading
              v-if="shouldShownInfinityHandler"
              ref="infiniteLoading"
              @infinite="infiniteHandler"
            >
              <div slot="spinner" class="loader-infinity"></div>
            </infinite-loading>
          </div>
        </div>

        <div v-if="!hasResults" class="objects-page__no-results">
          <img
            src="@/assets/images/no-results-03.webp"
            alt="EasyBase | No search results"
            width="320"
            height="320"
          />
        </div>

        <SearchResultsCounter
          :count="objects.results.length"
          :general="resultsCount"
          class="objects-page__search-results"
        />

        <mobile-header
          v-if="isMobile"
          :objectsCount="objects.results.length"
          :totalCount="resultsCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { NOTIFICATIONS } from '../helpers/constants';

import Simplebar from 'simplebar-vue';
import InfiniteLoading from 'vue-infinite-loading';

import CatalogCard from '@/components/app/CatalogCard.vue';
import FilterObjects from '@/components/app/filters/filter-my-objects/FilterObjects';
import MobileHeader from '@/components/app/mobile-header/mobile-header.vue';
import SearchResultsCounter from '@/components/app/SearchResultsCounter.vue';

import LabelsMixin from '@/mixins/LabelsMixin';
import ResponsiveMixin from '@/mixins/responsive';

export default {
  name: 'ObjectsPage',
  mixins: [LabelsMixin, ResponsiveMixin],
  components: {
    Simplebar,
    CatalogCard,
    InfiniteLoading,
    FilterObjects,
    MobileHeader,
    SearchResultsCounter
  },
  data() {
    return {
      objectsPopup: false,
      apartments: undefined,
      buildingType: 'flat',
      isLoaded: false,
      scrollContentEl: null
    };
  },
  computed: {
    ...mapState({
      objects: (state) => state.my_objects.objects,
      loader: (state) => state.main.loaderObject
    }),
    shouldShownInfinityHandler({ objects }) {
      return objects.results.length !== objects.count;
    },
    resultsCount() {
      return this.objects.count || 0;
    },
    hasResults() {
      return (
        this.objects && this.objects.results && this.objects.results.length
      );
    }
  },
  created() {
    this.handleGetMicroDistrict();
    this.handleGetStreet();
    this.handleGetResidentialComplex();
    this.handleGetCities();
  },
  async mounted() {
    this.scrollContentEl = document.querySelector('.catalog-item-container');

    await this.handleGetObjects();
    this.isLoaded = true;

    if (this.isDesktop) {
      this.setupHorizontalScroll();
    }
  },
  destroyed() {
    if (this.isDesktop) {
      if (this.scrollBar) {
        this.scrollBar.removeEventListener('scroll', this.handleScroll);
      }

      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    ...mapActions([
      'handleGetObjects',
      'handleGetMicroDistrict',
      'handleGetStreet',
      'handleGetResidentialComplex',
      'handleGetCities'
    ]),
    editObject(apartment) {
      this.objectsPopup = true;
      this.apartments = apartment;
    },
    async infiniteHandler($state) {
      this.loading = true;
      const count = this.objects.next;
      if (!this.loader) {
        await this.handleGetObjects({ page: count });
      }

      setTimeout(() => {
        $state.loaded();
        this.loading = false;
      }, 1000);
    },
    handleScroll() {
      if (!this.hasResults) return;

      const sumValue = Math.ceil(
        this.scrollBar.offsetWidth + this.scrollBar.scrollLeft
      );

      if (
        this.isDesktop &&
        this.scrollContentEl &&
        sumValue >= this.scrollContentEl.offsetWidth
      ) {
        const count = this.objects.next;

        if (!count) {
          // isset next page
          this.$notify({
            group: 'app',
            type: 'success',
            text: NOTIFICATIONS.viewedAllObjects,
            ignoreDuplicates: true
          });
          return;
        }

        this.isAllowStaticShowObjects = true;
        this.handleGetObjects({ page: count });
      }
    },
    setupHorizontalScroll() {
      this.scrollBar = document.querySelector('.simplebar-content-wrapper');

      if (this.scrollBar) {
        this.scrollBar.addEventListener('scroll', this.handleScroll);
      }

      window.addEventListener('scroll', this.handleScroll);
    }
  }
};
</script>

<style lang="sass" scoped>
.objects-page
  &__row
    display: flex
    flex: 0 0 100%
    min-width: 0

  &__aside
    flex: 0 0 var(--catalog-filter-width)
    max-width: var(--catalog-filter-width)

    @include media-breakpoint-down(md)
      display: none !important

  &__filter
    min-height: 100%
    padding-top: 0.625rem
    box-shadow: 0 0 15px 5px #153350

  &__main
    position: relative
    display: flex
    flex-direction: column
    flex: 0 0 calc(100% - var(--catalog-filter-width))
    max-width: calc(100% - var(--catalog-filter-width))
    min-width: 0
    padding: 1rem 1rem 0.5rem 1rem

    @include media-breakpoint-down(md)
      flex: 0 0 100%
      max-width: 100%
      padding: 0
      padding-top: 3rem

  &__search-results
    position: absolute
    z-index: 9
    left: 0.75rem
    bottom: 0.5rem

    @include media-breakpoint-down(md)
      display: none !important

  &__no-results
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    flex: 1 1 auto
    padding: 2rem

    img
      max-width: 100%
      max-height: 100%
      object-fit: contain

      @include media-breakpoint-down(md)
        width: 15rem
        height: 15rem

  &__pc-list
    list-style: none
    display: flex
    flex-direction: row
    gap: 0.75rem var(--catalog-list-gap)
    margin: 0
    padding-left: 0
    padding-bottom: 2.25rem

  &__mob-list
    list-style: none
    padding-left: 0
</style>
