// eslint-disable-next-line import/no-unresolved
import api from '@/api';

export const state = {
  notifications: []
};

export const getters = {
  getNotifications: (state) => state.notifications
};

export const actions = {
  async handleGetNotifications({ commit }) {
    try {
      const response = await api.getNotifications();

      if (!response || typeof response !== 'object') {
        throw new Error('Invalid response from API');
      }

      const { data } = response;
      if (!data || typeof data !== 'object') {
        throw new Error('Response data is missing or invalid');
      }

      const { results } = data;
      if (!Array.isArray(results)) {
        throw new Error('Results are missing or not an array');
      }

      commit('SET', {
        type: 'notifications',
        value: results
      });
    } catch (e) {
      console.error(e);
    }
  },
  updateNotificationStatus({ state, commit }, id) {
    let notification = state.notifications.find((n) => n.id === id);

    if (notification) {
      notification.is_seen = true;

      commit('SET', {
        type: 'notifications',
        value: state.notifications
      });
    }
  }
};

export const mutations = {
  SET(state, { type, value }) {
    state[type] = value;
  }
};
