<template>
  <section class="landing-benefits">
    <div class="landing-benefits__container landing-container">
      <div class="landing-benefits__wrap">
        <div class="landing-benefits__row">
          <div class="landing-benefits__col landing-benefits__col--text">
            <h2 class="landing-benefits__title" data-aos="fade-right">
              Ви отримуєте:
            </h2>
          </div>

          <div class="landing-benefits__col landing-benefits__col--items">
            <ul class="landing-benefits__items">
              <li
                v-for="(benefit, benefitIndex) in benefits"
                :key="benefit.id"
                data-aos="fade-up"
                :data-aos-delay="benefitIndex * 100 + 100"
              >
                <BenefitCard :benefit="benefit" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BenefitCard from './BenefitCard.vue';

import Benefit01Img from '@/assets/landing/dollar.svg';
import Benefit02Img from '@/assets/landing/bot.svg';
import Benefit03Img from '@/assets/landing/mail.svg';
import Benefit04Img from '@/assets/landing/phone.svg';
import Benefit05Img from '@/assets/landing/box.svg';
import Benefit06Img from '@/assets/landing/laptop.svg';

export default {
  name: 'LandingBenefitsSection',
  components: {
    BenefitCard
  },
  data() {
    return {
      benefits: [
        {
          id: '0',
          img: Benefit01Img,
          text: 'Плату за надану Вами інформацію'
        },
        {
          id: '1',
          img: Benefit02Img,
          text: 'Бот з інформуванням про нові об`єкти'
        },
        {
          id: '2',
          img: Benefit03Img,
          text: 'Можливість відправляти об`єкти у месенджери'
        },
        {
          id: '3',
          img: Benefit04Img,
          text: 'Мобільну версію - вся база у кишені'
        },
        {
          id: '4',
          img: Benefit05Img,
          text: 'Архів за останні 5 років'
        },
        {
          id: '5',
          img: Benefit06Img,
          text: 'EasyHata - база для ваших клієнтів'
        }
      ]
    };
  }
};
</script>

<style lang="sass">
.landing-benefits
  overflow: hidden
  background-color: var(--color-white)
  color: var(--color-easy-black)

  &__wrap
    padding: 10rem 0 5rem

    @include media-breakpoint-down(lg)
      padding: 6rem 0 3rem

    @include media-breakpoint-down(sm)
      padding: 4rem 0 2rem

  &__row
    --col-width: 100%
    --col-gap: 1.375rem

    display: flex
    flex-wrap: wrap
    gap: var(--col-gap) 0
    margin: 0 calc(var(--col-gap) / 2 * -1)

    @include media-breakpoint-up(sm)
      --col-gap: 2rem

    @include media-breakpoint-up(lg)
      --col-gap: 3.5rem

      flex-wrap: nowrap

  &__col
    padding: 0 calc(var(--col-gap) / 2)

    &--text
      flex-shrink: 0

    &--items
      flex: 1 1 auto

  &__title
    font-size: 3rem
    font-weight: 500
    line-height: 1.05

    @include media-breakpoint-down(lg)
      font-size: 2rem

    @include media-breakpoint-down(sm)
      font-size: 1.5rem

  &__items
    --col-width: 100%
    --col-gap: 0.625rem

    list-style: none
    display: flex
    flex-wrap: wrap
    gap: var(--col-gap) 0
    margin: 0 calc(var(--col-gap) / 2 * -1)
    padding-left: 0

    @include media-breakpoint-up(md)
      --col-width: 50%
      --col-gap: 1rem

    @include media-breakpoint-up(xl)
      --col-gap: 2.5rem

    > li
      flex: 0 0 var(--col-width)
      max-width: var(--col-width)
      padding: 0 calc(var(--col-gap) / 2)
</style>
