<template>
  <div
    class="login-form-wrap"
    :class="{ _loading: $store.getters.authStatus === 'loading' }"
  >
    <div
      v-if="$store.getters.authStatus === 'loading'"
      class="login-form-wrap__loading"
    >
      <img src="@/assets/images/loading.gif" alt="Loading ..." />
    </div>

    <form
      class="login-form-wrap__form login-form-wrap__form--centered login-form"
      @submit.prevent="register"
    >
      <div v-if="!statusPhone.phone" class="login-form__group">
        <div class="login-form__input-wrap">
          <icon name="required_icon" class="login-form__required" />

          <PhoneInput v-model="username" mode="landing" />
        </div>

        <div class="login-form__disclaimer">
          <span>Введіть номер телефону</span>
        </div>
      </div>

      <div v-if="statusPhone.phone && !statusCode" class="login-form__group">
        <div class="login-form__input-wrap">
          <icon name="required_icon" class="login-form__required" />

          <div class="login-form__input">
            <input maxlength="4" v-model="code" placeholder="Код" />
          </div>
        </div>

        <div class="login-form__disclaimer">
          <span>Введіть код із SMS</span>
        </div>
      </div>

      <div v-if="statusCode" class="login-form__group">
        <div class="login-form__input-wrap">
          <icon name="required_icon" class="login-form__required" />

          <PasswordInput
            v-model="password"
            mode="landing"
            placeholder="Пароль"
          />
        </div>

        <div class="login-form__disclaimer">
          <span>Придумайте пароль не менше 6 символів</span>
        </div>

        <div class="btn-group-toggle form-required-container pl-3">
          <label class="agreed align-items-center">
            <icon name="required_icon" class="login-form__required" />

            <input v-model="consent" type="checkbox" />

            <div class="btn-check-border mt-0">
              <icon name="check_icon" class="btn-check-icon" />
            </div>

            <div>Згоден з умовами та правилами при реєстрації</div>
          </label>
        </div>

        <b-button class="btn-rules" v-b-modal.terms-popup>
          Умови та правила
        </b-button>
      </div>

      <div v-if="errorsAuth.phone_error" class="login-form__error">
        {{ errorsAuth.phone_error }}
      </div>

      <div v-if="errorsAuth.phone" class="login-form__error">
        {{ errorsAuth.phone[0] }}
      </div>

      <div v-if="errorsAuth.password" class="login-form__error">
        {{ errorsAuth.password[0] }}
      </div>

      <div v-if="errorsAuth.username" class="login-form__error">
        {{ errorsAuth.username[0] }}
      </div>

      <div v-if="errorsAuth.non_field_errors" class="login-form__error">
        {{ errorsAuth.non_field_errors[0] }}
      </div>

      <div v-if="errorsAuth.error_codes" class="login-form__error">
        {{ errorsAuth.error_codes }}
      </div>

      <div v-if="!statusPhone.phone" class="login-form__group">
        <button
          class="login-form__submit-btn"
          :class="username.length <= 8 ? 'disabled ' : ''"
          @click.prevent="sendPhone"
        >
          Отримати код
        </button>
      </div>

      <div v-if="statusPhone.phone && !statusCode" class="login-form__group">
        <button class="login-form__submit-btn" @click.prevent="confirmCode">
          Підтвердити код
        </button>
      </div>

      <div v-if="statusCode" class="login-form__group">
        <button
          type="submit"
          class="login-form__submit-btn"
          :disabled="!consent && password.length > 5"
        >
          Почати працювати
        </button>
      </div>
    </form>

    <TermsConditionsPopup />
  </div>
</template>

<script>
import TermsConditionsPopup from '@/components/popups/TermsConditionsPopup';
import { mapActions, mapState, mapMutations } from 'vuex';
import { getUrlParams } from '@/utils/url-parse-to-object';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import PasswordInput from '@/components/forms/PasswordInput.vue';

export default {
  components: {
    TermsConditionsPopup,
    PhoneInput,
    PasswordInput
  },
  data() {
    return {
      consent: false,
      countryCode: '+380',
      username: '',
      password: '',
      code: ' '
    };
  },
  computed: {
    ...mapState({
      errorsAuth: (state) => state.auth.errorsAuth,
      statusCode: (state) => state.auth.statusCode,
      statusPhone: (state) => state.auth.statusPhone
    })
  },
  beforeMount() {
    this.code = '';
    const { inv: invite_link } = getUrlParams(window.location.search);
    if (!invite_link || invite_link === 'LnIUe') {
      this.$router.push('/');
    }
  },
  methods: {
    ...mapActions(['handleSendPhone', 'handleSendCode', 'handleRegister']),
    ...mapMutations(['RESET_REGISTER', 'RESET_ERRORS']),
    async sendPhone() {
      await this.RESET_ERRORS();

      const payload = {
        path: 'sms',
        phone: `0${this.username}`.replace(/\s+/g, '')
      };
      await this.handleSendPhone(payload);
    },
    async confirmCode() {
      const {
        code,
        statusPhone: { phone }
      } = this;
      await this.RESET_ERRORS();

      if (code.length >= 4 && phone)
        await this.handleSendCode({ code, path: 'sms' });
    },

    register() {
      let { username, password } = this;
      const { inv: invite_link } = getUrlParams(window.location.search);
      username = `0${username}`.replace(/\s+/g, '');
      this.handleRegister({ username, password, invite_link });
    }
  },
  destroyed() {
    this.RESET_ERRORS();
    this.RESET_REGISTER();
  }
};
</script>

<style>
.register-form {
  align-self: center;
}
@media screen and (max-width: 991px) {
  .register-form {
    top: 30px;
  }
}
.login-form form .block-phone *:not(:last-child) {
  margin-bottom: 0px;
}
.form-register {
  width: 100%;
}
.btn-rules {
  margin: 0 auto;
  display: block;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.block-phone .form-input {
  outline: 0;
}
.block-phone .form-input:first-child {
  width: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0;
}
.block-phone .form-input:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0;
}
.login-form.main-content {
  flex: 0;
}
.register-form .form-input.country-code {
  border-right: none;
}
.register-form .form-input.phone-number {
  border-left: none !important;
  padding-left: 5px !important;
}
.register-form .btn-filter {
  height: 38px !important;
  padding: 10px 0 !important;
}

.agreed .form-required-icon {
  position: absolute;
  top: 19px !important;
  left: 2px;
}

.agreed {
  display: flex;
  align-items: top;
  padding: 10px;
  margin-bottom: 0;
}

.agreed .btn-check-border {
  margin-right: 5px;
  margin-top: 4px;
}

.agreed .btn-check-border svg {
  border: 1px solid #308b9d !important;
}

.agreed input {
  display: none;
}
</style>
