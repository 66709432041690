<template>
  <div
    class="login-form-wrap"
    :class="{ _loading: $store.getters.authStatus === 'loading' }"
  >
    <div
      v-if="$store.getters.authStatus === 'loading'"
      class="login-form-wrap__loading"
    >
      <img src="@/assets/images/loading.gif" alt="Loading ..." />
    </div>

    <form class="login-form-wrap__form login-form" @submit.prevent="login">
      <div v-if="errors.login" class="login-form__error">
        {{ errors.login }}
      </div>

      <div class="login-form__group">
        <div class="login-form__input-wrap">
          <icon name="required_icon" class="login-form__required" />

          <PhoneInput v-model="username" mode="landing" />
        </div>

        <div v-if="errors.username" class="login-form__error">
          {{ errors.username[0] }}
        </div>
      </div>

      <div class="login-form__group">
        <div class="login-form__input-wrap">
          <icon name="required_icon" class="login-form__required" />

          <PasswordInput
            v-model="password"
            placeholder="Пароль"
            mode="landing"
          />
        </div>

        <div v-if="errors.password" class="login-form__error">
          {{ errors.password[0] }}
        </div>
      </div>

      <div v-if="errors.non_field_errors" class="login-form__error">
        {{ errors.non_field_errors[0] }}
      </div>

      <div class="login-form__group">
        <button type="submit" class="login-form__submit-btn">Увійти</button>
      </div>
    </form>

    <div class="text-center">
      <router-link to="/reset-password" class="login-form-wrap__forgot-link">
        Забув/ла пароль
      </router-link>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PhoneInput from '@/components/forms/PhoneInput.vue';
import PasswordInput from '@/components/forms/PasswordInput.vue';

export default {
  name: 'LoginForm',
  components: {
    PhoneInput,
    PasswordInput
  },
  data() {
    return {
      countryCode: '+380',
      username: '',
      password: '',
      errors: []
    };
  },
  methods: {
    login() {
      this.errors = [];

      this.$store
        .dispatch('login', {
          username: `0${this.username}`.replace(/\s+/g, ''),
          password: this.password
        })
        .then((response) => {
          if (!response.token) {
            this.errors = response.response.data;
            Object.values(this.errors).forEach((val) => {
              Vue.prototype.$notify({ group: 'app', type: 'error', text: val });
            });

            return;
          }

          this.$router.push('/');
        });
    }
  }
};
</script>
