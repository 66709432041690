<template>
  <div :class="$bem()">
    <div class="landing-container">
      <div :class="$bem('inner')">
        <h2 :class="$bem('title')" data-aos="fade-up">Працюємо для Вас</h2>

        <div :class="$bem('img')">
          <img
            src="@/assets/landing/map.webp"
            alt="EasyBase | Ukraine Map"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'landing-workfor-screen',
  mixins: [component]
};
</script>
