<template>
  <transition name="fade">
    <div v-if="showQuote" class="rocket-launch">
      <div
        v-if="currentQuote"
        v-html="currentQuote"
        class="rocket-launch__quote"
        @click.prevent="closeQuote"
      ></div>

      <div
        v-if="showRocket"
        class="rocket-launch__rocket"
        :class="{ _animated: showRocket }"
      >
        <img src="@/assets/images/rocket-anim.webp" alt="" loading="lazy" />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const DISPLAY_DURATION = 7000;
const AUTO_DISPLAY_DELAY = 1000;
const STORAGE_KEY = 'lastRocketShowDate';

export default {
  name: 'RocketLaunch',
  computed: {
    ...mapGetters(['getShowQuote', 'getCurrentQuote', 'getShowRocket']),
    showRocket() {
      return this.getShowRocket;
    },
    showQuote() {
      return this.getShowQuote;
    },
    currentQuote() {
      return this.getCurrentQuote;
    }
  },
  watch: {
    '$route.name'(newRoute) {
      if (newRoute === 'home') {
        setTimeout(() => {
          this.autoTriggerRocket();
        }, AUTO_DISPLAY_DELAY);
      }
    }
  },
  methods: {
    ...mapActions(['triggerRocket', 'closeQuote']),
    shouldShowRocket() {
      const lastShowDate = localStorage.getItem(STORAGE_KEY);
      const now = new Date();
      const today = now.toISOString().split('T')[0];

      if (!lastShowDate || lastShowDate !== today) {
        if (now.getHours() >= 7) {
          localStorage.setItem(STORAGE_KEY, today);
          return true;
        }
      }

      return false;
    },
    autoTriggerRocket() {
      if (this.shouldShowRocket()) {
        this.triggerRocket();
        setTimeout(() => {
          this.closeQuote();
        }, DISPLAY_DURATION);
      }
    }
  }
};
</script>

<style lang="sass">
.rocket-launch
  position: fixed
  z-index: 9999
  left: 0
  top: 0
  width: 100%
  height: 100%

  &__quote
    position: fixed
    z-index: 98
    left: 0
    top: 0
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    padding: 1.5rem
    background-color: rgba(0, 0 , 0, 0.5)
    font-size: 4vw
    font-weight: 700
    line-height: 1
    text-align: center
    text-shadow: 0 0 1rem var(--color-black), 1rem 1rem 20rem var(--color-black)

    +media-breakpoint-down(md)
      background-color: rgba(0, 0 , 0, 0.65)
      font-size: 1.5rem

  &__rocket
    position: fixed
    z-index: 99
    left: 0
    bottom: 0
    width: 7.5rem
    opacity: 0
    pointer-events: none

    +media-breakpoint-down(md)
      width: 6rem

    &._animated
      opacity: 1
      animation: rocketLaunch 4s ease-in-out forwards

      +media-breakpoint-down(md)
        animation-duration: 2s

    img
      display: block
      width: 100%
      height: auto
      object-position: contain

@keyframes rocketLaunch
  0%
    bottom: 0

  100%
    left: 100%
    bottom: 100%

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.5s

.fade-enter,
.fade-leave-to
  opacity: 0
</style>
