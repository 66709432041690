<template>
  <div :class="$bem()">
    <div class="landing-container">
      <div :class="$bem('inner')">
        <div :class="$bem('description')">
          <h2 data-aos="fade-right">Нам довіряють</h2>

          <p data-aos="fade-right" data-aos-delay="100">
            Наш сайт - це відмінний інструмент для професійних ріелторів, які
            прагнуть ефективно працювати та збільшувати свої доходи. Досвідчені
            фахівці вже відзначили наш сервіс як надійний та перевірений, що дає
            їм можливість зекономити свій час та зосередитися на роботі з
            клієнтами. Приєднуйтеся до наших успішних користувачів та
            зареєструйтеся на нашому сайті вже сьогодні!
          </p>
        </div>

        <div :class="$bem('reviews')">
          <img
            src="@/assets/landing/reviews/sticker-cat.webp"
            alt="sticker"
            loading="lazy"
            :class="$bem('sticker')"
          />

          <reviews-slider />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import reviewsSlider from './reviews-slider.vue';

export default {
  components: { reviewsSlider },
  mixins: [component]
};
</script>
