<template>
  <div class="benefit-card">
    <div class="benefit-card__row">
      <div class="benefit-card__img">
        <img :src="benefit.img" :alt="benefit.text" />
      </div>

      <div v-if="benefit.text" class="benefit-card__text">
        <p v-html="benefit.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BenefitCard',
  props: {
    benefit: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass">
.benefit-card
  --img-width: 3.25rem
  --img-height: 4.5rem

  @include media-breakpoint-down(md)
    --img-width: 2.5rem
    --img-height: 3.5rem

  @include media-breakpoint-down(sm)
    --img-width: 2rem
    --img-height: 3rem

  &__row
    display: flex
    align-items: center
    gap: 1.25rem

    @include media-breakpoint-down(lg)
      gap: 1rem

    @include media-breakpoint-down(sm)
      gap: 0.75rem

  &__img
    flex: 0 0 var(--img-width)
    width: var(--img-width)
    height: var(--img-height)

    img
      display: block
      width: 100%
      height: 100%
      object-fit: contain

  &__text
    flex: 1 1 auto
    font-size: 1.5rem
    font-weight: 300
    line-height: 1.2
    color: var(--color-easy-black)
    text-wrap: balance

    @include media-breakpoint-down(lg)
      font-size: 1.25rem

    @include media-breakpoint-down(sm)
      font-size: 1rem
      line-height: 1.4

    p
      margin-bottom: 0
</style>
