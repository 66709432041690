<template>
  <div :class="$bem()">
    <landing-header />

    <LandingHeroSection />

    <LandingBenefitsSection />

    <landing-info-screen />

    <landing-features />

    <landing-about-screen />

    <landing-reviews />

    <landing-workfor-screen />

    <landing-questions-screen />

    <landing-footer />
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

import component from '@/mixins/component';
import LandingHeroSection from '@/components/auth/LandingHeroSection.vue';
import LandingBenefitsSection from '@/components/auth/LandingBenefitsSection.vue';
import LandingAuthScreen from '@/components/auth/landing-auth-screen';
import LandingHeader from '@/components/auth/landing-header';
import LandingInfoScreen from '@/components/auth/landing-info-screen';
import LandingAboutScreen from '@/components/auth/landing-about-screen';
import LandingWorkforScreen from '@/components/auth/landing-workfor-screen';
import LandingQuestionsScreen from '@/components/auth/landing-questions-screen';
import LandingFooter from '@/components/auth/landing-footer';
import LandingFeatures from '@/components/auth/landing-features';
import LandingReviews from '@/components/auth/landing-reviews';
import { closeWebSocket } from '@/services/websocketService';

export default {
  name: 'Login',
  mixins: [component],
  components: {
    LandingHeroSection,
    LandingBenefitsSection,
    LandingAuthScreen,
    LandingHeader,
    LandingInfoScreen,
    LandingAboutScreen,
    LandingWorkforScreen,
    LandingQuestionsScreen,
    LandingFooter,
    LandingFeatures,
    LandingReviews
  },
  created() {
    if (this.$route.redirectedFrom === '/logout') {
      this.$store.dispatch('logout').catch((err) => console.log(err, 'error'));
      closeWebSocket();
    } else if (localStorage.getItem('accessToken')) {
      this.$router.push('/');
    }
  },
  mounted() {
    AOS.init({
      duration: 1000,
      offset: 140
    });
  }
};
</script>

<style lang="sass">
.Login
  margin-top: 0 !important
  margin-bottom: 0 !important
  display: block !important
</style>
